import moment from 'moment'
import {
  AllowedReservationTimes,
  CurrencyCode,
  Weekday,
  PublishingStatus,
  TieredUnit,
  ReservedSlot,
  SpacentSellableType,
  SpaceType,
} from '../../types'
import { en, openTimesForDays } from './en'
import { Translations } from './types'
import { generateTimeFormatters } from './generators'
import { HeardFromSource } from '../../constants'
import { fiCountryCodeTranslations } from './country_code_translations'

const timeFormats = {
  ...en.timeFormats,
  momentWebDayFormat: 'D.M.YYYY',
  momentMonthFormat: 'M/YYYY',
  momentDateFormat: 'DD.MM.YYYY',
  momentDateTimeFormat: 'DD.MM.YYYY HH:mm',
  momentTimeFullFormat: 'H.mm',
  momentTimeHourFormat: 'H',
  weekOrder: [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ] as Weekday[],
  weekDayAbbreviations: {
    monday: 'Ma',
    tuesday: 'Ti',
    wednesday: 'Ke',
    thursday: 'To',
    friday: 'Pe',
    saturday: 'La',
    sunday: 'Su',
  },
}

const spacentSellableNames: Record<SpacentSellableType, string> = {
  [SpacentSellableType.consumerSubscription]: 'henkilökohtainen jäsenyys',
  [SpacentSellableType.corporate1PersonSubscription]:
    'yhden hengen jäsenyys organisaatiolle',
  [SpacentSellableType.corporateSubscription]:
    'erikseen sovittu jäsenyys organisaatiolle',
  [SpacentSellableType.subscriptionTest]:
    'kokeilu yhden hengen jäsenyys organisaatiolle',
  [SpacentSellableType.consumerCoworkingPass]:
    'henkilökohtainen coworking-passi',
  [SpacentSellableType.coworkingPass1person]:
    'yhden hengen coworking-passi organisaatiolle',
  [SpacentSellableType.coworkingPass5persons]:
    '1-5 hengen coworking-passi organisaatiolle',
  [SpacentSellableType.coworkingPass10persons]:
    '6-10 hengen coworking-passi organisaatiolle',
}

export const constantStrings = {
  ...en.constantStrings,
  ...spacentSellableNames,

  // Languages
  en: 'Englanti',
  fi: 'Suomi',
  zh: 'Kiina',
  sv: 'Ruotsi',

  // general
  collapse: 'Pienennä',
  language: 'Kieli',
  ok: 'OK',
  close: 'Sulje',
  reserve: 'Varaa',
  cancel: 'Peruuta',
  delete: 'Poista',
  cancelReservation: 'Peruuta varaus',
  cancelReservations: 'Peruuta valitut varaukset',
  makeANewReservation: 'Tee uusi varaus',
  continue: 'Jatka',
  and: 'ja',
  unknownError:
    'Tapahtui tuntematon virhe, yritä uudelleen.\nJos virhe esiintyy usein, ota yhteyttä hello@spacent.com',
  hide: 'Piilota',
  blockReservations: 'Estä varaukset',
  forceHide: 'Estä uudet mutta jätä olemassaolevat varaukset voimaan',
  notifyOverlappingReservees: `Syötä viesti asiakkaille, joiden varaukset osuvat piilotettavaan aikaan.\n\nKerro myös jokin tapa ottaa yhteyttä viestin lähettäjään.\n\nJos he voivat käyttää tiloja varaaminaan aikoina kuten normaalisti, voit välttää viestin lähettämisen jättämällä sen tyhjäksi,`,
  select: 'Valitse...',
  other: 'Muu...',
  noOptions: 'Ei saatavilla',
  closeWithoutSaving: 'Sulje tallentamatta?',
  yes: 'Kyllä',
  no: 'Ei',
  save: 'Tallenna',
  saveInformation: 'Tallenna tiedot',
  search: 'Etsi...',
  clear: 'Peruuta',
  copy: 'Kopioi',
  copied: 'Kopioitu!',
  defaultConfirmModalTitle: 'Ennen kuin jatkat',
  generalErrorTitle: 'Voi ei',
  generalErrorMessage: 'Jotain meni pieleen, kokeile myöhemmin uudestaan.',
  giveFeedback: 'Anna palautetta',
  noFeedback: 'Palautetta ei ole vielä annettu',
  givenRating: 'Annettu arvosana',

  // Surveys
  heardFromSourceQuestionnaireTitle: 'Mistä löysit meidät?',
  heardFromSourceQuestionnaireDescription: '',

  // dates
  yesterday: 'Eilen',
  today: 'Tänään',
  tomorrow: 'Huomenna',
  reservationOnDate: 'Sinulla on varaus tälle päivälle tilaan ',

  // nav
  reservations: 'Varaukset',
  spaces: 'Tilat',
  logOut: 'Kirjaudu ulos',
  // nav -- admin
  myOrganization: 'Organisaationi',
  locations: 'Lokaatiot',
  usermanagement: 'Käyttäjät',
  usergroups: 'Käyttäjäryhmät',
  people: 'Ihmiset',
  managedUsergroups: 'Käyttäjäryhmät',
  personalUsergroups: 'Henkilökohtaiset käyttäjäryhmät',
  settings: 'Asetukset',
  basicInformation: 'Perustiedot',
  billing: 'Laskutus',
  servicePlanAndUse: 'Tilaus ja käyttöaste',
  appHomeView: 'Sovelluksen kotinäkymä',

  // service plan management
  memberships: 'Jäsenyyttä',
  members: 'Jäsentä',
  startingDate: 'Alkaen',
  adjustNumberOfMemberships: 'Muuta jäsenyyksien määrää',
  toChangeServicePlanContactUsAt:
    'Ota yhteyttä jos tilaustasi tarvitsee muuttaa:',
  utilizationDuringCurrentPeriod: 'Käyttö tässä kuussa',
  notCurrentlyInUse: 'Tämä ei ole teillä vielä käytössä.',
  accesses: 'Käyntiä',
  total: 'Yhteensä',
  recurringTotal: 'Yhteensä kuukausittain',
  noAccessesToBePaidThisMonth:
    'Tässä kuussa teille ei ole vielä kertynyt maksullisia käyntejä.',
  totalServiceCost:
    'Seuraavalle laskulle tähän mennessä kertyneet kustannukset kaikkiaan',
  noCostsYet: 'Kuluja ei ole vielä kertynyt.',
  invoicingByHighestNumberOfMemberships:
    'Kukin jakso laskutetaan korkeimman sen aikana voimassaolleen jäsenmäärän mukaan.',
  noChangeAgainToday:
    'Jäsenmäärää on muutettu tänään. Seuraavan muutoksen voi ajoittaa aikaisintaan huomiselle.',
  discount: 'alennus',

  // payment methods
  cash: 'Käteinen',
  invoice: 'Lasku',
  hostInvoice: 'Operaattorin laskutus',
  subscription: 'Jäsenyys',
  creditCard: 'Luottokortti',

  // login
  yourWorkEmail: 'Työsähköpostisi',
  welcomeTitle: 'Tervetuloa uuteen toimistoosi!',
  welcomeMessage:
    'Varaa työpiste tai neuvotteluhuone omalta toimistoltasi kirjautumalla sisään.',
  welcomeMessageContinued:
    'Coworking-tiloja pääset varaamaan Spacentin sovelluksesta puhelimella.',
  loginFailed: 'Kirjautuminen epäonnistui.',
  getStartedCTA: 'Aloita',
  returnToLogin: 'Palaa kirjautumissivulle',
  organizationNotFound:
    'Sinulla ei ole vielä asiakassuhdetta.\nOta yhteyttä hello@spacent.com',
  emailTokenVerificationFailed:
    'Sähköpostin vahvistus epäonnistui.\nYritä uudelleen. Jos virhe esiintyy usein, ota yhteyttä hello@spacent.com',
  userDataFetchingFailed:
    'Tietojen hakeminen epäonnistui.\nJos virhe esiintyy usein, ota yhteyttä hello@spacent.com',
  startBySigningIn: 'Aloita kirjautumalla sisään.',
  beforeWeStart: 'Ennen kuin aloitamme.',
  pleaseFillNameAndPhone:
    'Ole hyvä ja täytä nimesi ja puhelinnumerosi. Tätä tietoa tarvitaan yhteydenpitoon aulan vastaanottovirkailijan kanssa.',
  pleaseAcceptTermsAndConditions: 'Ole hyvä ja lue käyttöehdot',
  yourName: 'Nimi',
  yourPhone: 'Puhelinnumero',
  agreeAndContinue: 'Hyväksy ja jatka',
  bySelectingAgree: 'Painamalla Hyväksy ja jatka nappia, hyväksyn Spacentin',
  termsAndConditions: 'Käyttöehdot',
  privacyPolicy: 'Tietosuojakäytännön',
  invalidAccessToken: 'Käyttöoikeustietue ei kelpaa.',
  invalidEmail: 'Tarkista sähköpostiosoite.',

  // reserving
  'reservationTimespan:pre': 'Varaa alkaen',
  'reservationTimespan:mid': 'päättyen',
  reservationDays: 'päiväksi',
  reservationHours: 'tunniksi',
  reservationId: 'Varaustunniste:',
  tooLongReservationPeriod:
    'Voit varata tämän huoneen korkeintaan {amount} {units}.',
  // reserving -- admin
  tooManyReservationsForCapacity:
    'Liian monta varausta huoneen kapasiteetille!',
  changedReservationTime:
    'Varausaikojasi muutettiin päällekkäisten aikojen vuoksi.',

  // reservation confirmation
  confirmReservation: 'Varaa tila',
  confirmReservationAddress: 'Osoite',
  confirmReservationTimespan: 'Varauksen kesto',
  preReservationChecks: 'Varaustarkistukset',
  showPreReservationChecks: 'Näytä varausta edeltävät tarkistukset',
  alreadyReservedThis: 'Olet jo varannut tämän tilan valitulle ajalle',
  alreadyReservedSpace: 'Sinulla on jo varaus valitulle ajalle tähän tilaan:',

  // reservations -- admin
  bookingCalendar: 'Varaukset',
  calendar: 'Kalenteri',
  list: 'Lista',
  calendarIntegrationError:
    'Tiedon lataus organisaatiosi varausjärjestelmästä epäonnistui. Näytetyt varaustiedot saattavat olla puutteellisia.',
  noPaymentMethodsError:
    'Varauksen luominen epäonnistui. Valitulle tilalle ei ole saatavilla yhtään maksutapaa.',
  calendarNoSpacesBeginning: 'Sinulla ei näytä olevan vielä yhtään tiloja.',
  calendarNoSpacesLink: 'Voisit luoda sellaisen.',
  view: 'Näytä',
  searchFilter: 'Rajaukset',
  searchResults: 'Varaukset',
  start: 'Alku',
  end: 'Loppu',
  visitor: 'Vierailija',
  specificSpaces: 'Tietyt tilat',
  cancellationDialogLabel:
    'Halutessasi voit lisätä oman viestin vierailijalle lähtevään peruutusviestiin.',
  forExample: 'Esimerkiksi:',

  // reservations dialog -- admin
  createReservation: 'Luo varaus',
  viewReservation: 'Varauksen tiedot',
  reservationWhere: 'Sijainti',
  reservationStart: 'Alkaa',
  reservationEnd: 'Loppuu',
  reservedFor: 'Kävijä',
  reserveFor: 'Kävijä',
  organization: 'Organisaatio',
  paymentMethod: 'Maksutapa',
  invoiceReference: 'Laskun viite',
  yourReference: 'Viitteenne',
  reservationNotes: 'Muistiinpanot',
  cateringInformation: 'Catering-tiedot',
  addCateringItem: 'Lisää tuote',
  reservationRoomPrice: 'Huoneen hinta',
  reservationTotalPrice: 'Summa',
  updateReservation: 'Muuta varaus',
  myOrganizationInvoiceDetailsMissing:
    'Organisaation laskutustiedot puuttuvat. Ole hyvä ja lisää ne Organisaationi -kohdassa tai ota yhteyttä support@spacent.com',
  reservationNotFoundTitle: 'Varausta ei löytynyt!',
  reservationNotFoundMessage:
    'Mikäli se mielestäsi pitäisi löytyä, olethan yhteydessä tukeemme.',
  reservationCreated: 'Varaus luotu.',
  reservationUpdated: 'Varauksen muutos on tallennettu.',

  // spaces
  noAvailableSpaces:
    'Valitettavasti sopivaa vapaata tilaa ei löytynyt kyseiselle ajankohdalle.',
  cancelReservationBeforeChangingVisibility:
    'Sinun tulee perua tulevat varaukset ennen kuin teet muutoksia tilan näkyvyyteen.',
  includedInMeetingRoomPackage: 'Sisältyy neuvotteluhuonepakettiin',
  getQrCode: 'Näytä QR-koodi',
  qrCodeBlockTitle: 'Varausnäkymän QR-koodi',
  qrCodeBlockDescription:
    'Voit käyttää tätä QR-koodia tarjotaksesi käyttäjille nopeamman pääsyn tilan varausnäkymään.',

  // reservations
  noReservations: 'Sinulla ei ole aktiivisia varauksia.',

  // my organization -- admin
  nameAndBusinessId: 'Nimi ja Y-tunnus',
  organizationName: 'Organisaation nimi',
  businessIdLabel: 'Y-tunnus',
  authentication: 'Autentikaatio',
  authenticationType: 'Autentikaatiomenetelmä',
  authConfigured:
    'Spacent on asettanut organisaation autentikaatiomenetelmän. Jos haluat muuttaa menetelmää, ota yhteyttä',
  creditCardPayment: 'Luottokortti',
  pdfInvoice: 'Lähetä PDF-lasku sähköpostiini',
  pdfInvoiceReceiver: 'Sähköpostiosoite',
  useInvoicingOperator: 'Käytä laskutusoperaattoria',
  eInvoiceOperator: 'Verkkolaskuoperaattori',
  eInvoiceAddress: 'Verkkolaskuosoite',
  invoiceContact: 'Yhteyssähköpostiosoite',
  changeBillingMethod:
    'Jos haluat keskeyttää organisaatiosi Spacent-jäsenyyden, ole hyvä ja ota yhteyttä ',
  cardholderName: 'Kortinhaltijan nimi',
  cardholderEmail: 'Sähköpostiosoite',
  cardNumber: 'Kortin numero',
  cardExpiration: 'Kortin voimassaoloaika',
  cardCvc: 'Kortin turvanumero',
  creditCardDisclaimer:
    'Luottokorttimaksumme käsittelee Stripe. Maksukorttina käyvät yleisimmät luottokortit.',
  paymentInfoUpdated: 'Maksutiedot päivitetty.',
  billingMethod: 'Maksutapa',
  updateCard: 'Muuta kortti',
  deleteCard: 'Poista kortti',
  authorizePendingCardPayment: 'Vahvista korttimaksu',
  pendingAmount: 'Vahvistettava summa',
  paymentCard: 'Käytettävä kortti',
  cardEndingIn: 'päättyen numeroihin',
  paymentAuthorizationSuccess: 'Maksu vahvistettu onnistuneesti',
  paymentAuthorizationFail: 'Maksun vahvistaminen epäonnistui',

  // custom network
  locationNotAvailableForOrganization:
    'Tämä lokaatio ei ole saatavilla organisaatiollesi.',
  spaceNotAvailableRoomListTag: 'Ei saatavilla',
  customNetwork: 'Mukautettu verkosto',
  customNetworkSaved: 'Mukautettu verkostosi on nyt tallennettu. 🎉',
  customNetworkSavedError:
    'Voi ei 😭. Mukautetun verkostosi tallennus ei onnistunut!',
  customNetworkCountriesFailed:
    'Voi ei 😭. Saatavilla olevien maiden hakeminen epäonnistui!',
  customNetworkFetchFailed:
    'Voi ei 😭. Mukautetun verkostosi hakeminen epäonnistui!',
  customNetworkCountries: 'Mukautetun verkostosi maat',
  customNetworkCountriesExclude: 'Kaikki maat paitsi alempana valitut',
  customNetworkCountriesIncludeOnly: 'Ainoastaan alempana valitut maat',
  createCustomNetwork: 'Luo mukautettu verkosto',
  editCustomNetwork: 'Muokkaa mukautettua verkostoa',
  editCustomNetworkShort: 'Muokkaa',
  deleteCustomNetwork: 'Poista',
  deleteCustomNetworkConfirmation:
    'Oletko varma että haluat poistaa mukautetun verkoston?',
  customNetworkDeleted: 'Mukautettu verkostosi on nyt poistettu.',
  customNetworkDeletedError:
    'Voi ei 😭. Mukautetun verkostosi poisto epäonnistui!',
  customNetworkInitialInstructions:
    'Luo mukautettu verkosto niistä lokaatioista joita työntekijäsi voivat käyttää.',
  customNetworkCountriesInstructions:
    'Olet luonut mukautetun verkoston perustuen maihin.',

  // locations -- admin
  feedbackLocations: 'Lokaatioiden arviot ja palautteet',
  writtenFeedbackCount: 'Palautteita',
  allLocations: 'Kaikki lokaatiot',
  announcements: 'Tiedotteet',
  buildingManagement: 'Rakennusten hallinta',
  feedback: 'Arviot ja palautteet',
  addNewLocation: 'Lisää uusi lokaatio',
  operatorName: 'Lokaation nimi',
  address: 'Osoite',
  city: 'Kaupunki',
  country: 'Maa',
  seeExample: 'Katso esimerkki',
  website: 'Nettisivut',
  websiteTooltip:
    'Voit lisätä linkin omille nettisivuillesi. Linkki näytetään lokaation yhteydessä Spacentin nettisivuilla.',
  websiteUrl: 'Nettisivujen URL',
  metaTitleTextDefault: 'Työpisteet, projektitilat ja neuvotteluhuoneet',
  metaDescriptionDefault:
    'Spacent mahdollistaa etätyöskentelyn missä vain. Spacentin jäsenenä voit työskennellä sadoissa eri coworking-tiloissa ympäri Eurooppaa.',
  openGraphTitleTextDefault: 'Työpisteet, projektitilat ja neuvotteluhuoneet',

  // location editor -- admin
  locationAdded: 'Uusi Lokaatio on luotu! 🎉',
  locationAddError:
    'Oi voi 😭. Lokaation luominen epäonnistui. Ole hyvä ja tarkista lokaation tiedot.',
  locationSaved: 'Lokaatio on tallennettu! 🎉',
  locationSaveError:
    'Oi voi 😭. Lokaation tallentaminen epäonnistui. Ole hyvä ja tarkista lokaation tiedot.',
  locationEdit: 'Muokkaa lokaatiota',
  locationAdd: 'Luo uusi lokaatio',
  generalInformation: 'Yleistiedot',
  operatorNameHelper: 'Appissa näytettävä nimi lokaatiolle',
  addressHelper: 'Katuosoite, postinumero ja kaupunki',
  cityHelper:
    'Ainoastaan kaupungin nimi, jotta lokaatio löytyy helposti sen perusteella',
  operatorNameExists: 'on nimenä jo käytössä',
  coordinates: 'Koordinaatit',
  latitude: 'Leveysaste',
  longitude: 'Pituusaste',
  selectOnMap: 'Valitse kartalta',
  similarLocation: 'Samankaltainen lokaatio',
  nearbyLocation:
    'Toinen lokaatio on jo olemassa alle 30 metrin päässä valitusta sijainnista',
  locationInfo: 'Lokaation tiedot',
  description: 'Kuvaus',
  locationDescriptionPlaceholder:
    'Kohteen kuvauksessa voit kertoa tilakonseptista, kohteen palveluista (esim. ravintola- ja kahvilapalvelut kohteessa tai sen lähialueilla), julkisen liikenteen yhteyksistä, pysäköinnistä, antaa tarvittaessa lisää yhteystietoja tai kertoa, miten teillä on varauduttu COVID-19 pandemian myötä turvalliseen työskentelyyn.',
  arrivalInfo: 'Saapumistiedot',
  arrivalInfoPlaceholder:
    'Ohje, joka lähetetään varaajalle varauksen teon jälkeen. Täytyy sisältää ohjeet kohteeseen saapumista varten, esim. "ilmoittaudu aulassa ja näytä olemassaoleva varaus".',
  healthCommitmentTitle: 'Otsikko',
  healthCommitmentTitlePlaceholder: 'Terveyssitoumus',
  healthCommitmentContent: 'Sisältö',
  healthCommitmentContentPlaceholder:
    'Huomioithan nämä asiat kaikkien tilankäyttäjien turvallisuuden varmistamiseksi.',
  healthCommitmentChecks: 'Sitoumuksen kohdat',
  healthCommitmentChecksPlaceholder1:
    'Minulla ei ole koronavirukseen viittaavia oireita.',
  healthCommitmentChecksPlaceholder2:
    'En ole altistunut koronavirukseen sairastuneelle henkilölle.',
  healthCommitmentChecksPlaceholder3:
    'En ole vieraillut viimeisten 14 vuorokauden aikana maissa, joihin Suomen hallituksen matkustusrajoitukset ovat voimassa.',
  healthCommitmentNoChecks: 'Ei kohtia sitoumuksessa',
  healthCommitmentAddCheck: 'Lisää',
  specialOpeningHoursClosed: 'kiinni',
  specialOpeningHours: 'Poikkeukset aukioloaikoihin',

  lobbyServicesOpeningHours: 'Aulapalveluiden aukioloajat',
  lobbyNotAvailable: 'Ei aulapalvelua',
  lobbyAlwaysOpen: 'Auki 24/7',
  contactInfo: 'Yhteystiedot',
  phone: 'Puhelin',
  phoneHelper: 'Puhelinnumero kansainvälisessä muodossa, esim. +12345678',
  sms: 'Tekstiviestinumero',
  smsHelper: 'Numero kansainvälisessä muodossa, esim. +12345678',
  email: 'Sähköposti',
  sendBookingNotifications:
    'Lähetä jokaisesta varauksesta tiedote tähän osoitteeseen',
  bookingNotificationLanguage: 'Tiedotteiden kieli',
  calendarIntegration: 'Integraatio varausjärjestelmään',
  refreshCalendars: 'Päivitä',
  openingHours: 'Aukioloajat',
  openingHoursDescription:
    'Lokaation yksittäisille tiloille voi myös asettaa koko lokaation aukioloajoista poikkeavat aukiolot.',
  wifi: 'Langaton verkko',
  wifiHelp:
    'Nämä ohjeet langattoman verkon käyttöön näytetään vierailijalle varauksen tekemisen jälkeen.',
  wifiName: 'Verkon nimi',
  wifiPassword: 'Verkon salasana',
  locationPhoto: 'Kuva',
  onlyCoverPhoto: 'Ainoastaan ensimmäinen kansikuva näytetään.',
  issuesInForm:
    'Joissain lomakkeen kohdissa ylempänä oli puutteita. Ole hyvä ja tarkista.',
  dragPinToEntrance: 'Raahaa nasta lokaation sisäänkäynnin kohdalle',
  searchByAddress: 'Etsi osoitteen perusteella',
  amenities: 'Palvelut',
  locationGeneralInformationHelp:
    'YLEISTIEDOT lokaatiosta näytetään rakennuksen tiedoissa.',
  operatorNameHelp:
    'Lokaation nimi näytetään tilojen listassa konseptuaalisena nimenänne. Se voi olla työtilakonseptinne tai rakennuksenne nimi.',
  coordinatesHelp: 'Vedä osoitin sisäänkäynnin kohdalle kartalla.',
  amenitiesHelp:
    'VALITSE PALVELUT jotka kuuluvat varauksen hintaan. Joitain voi ainoastaan ostaa paikan päällä; näissä tapauksissa valitse myös "saatavilla lisämaksusta"',
  locationInfoHelp:
    'KUVAUS näytetään rakennuksen tiedoissa. ' +
    'Täytä tähän lokaation kuvaus, kuten palvelut ' +
    '(esim. ravintolat, kahvilapalvelut lokaatiossa tai lähistöllä), ' +
    'julkiset liikenneyhteydet, pysäköinti, tilojen konsepti sekä lisää yhteystietoja.\n' +
    'Tämä kuvaus näytetään myös Spacentin nettisivuilla.',
  arrivalInfoHelp:
    'SAAPUMISTIEDOT näytetään vierailijalle varauksen kirjaamisen valmistuttua. Hyvät saapumistiedot selvittävät, miten lokaatioon löytää sisään ja miten varaus on käytettävissä. ' +
    'Esimerkiksi: Ilmoittautukaa aulan vastaanotossa ja näyttäkää varauksenne Spacent-sovelluksesta.',
  healthCommitmentHelp:
    'TERVEYSSITOUMUS on varmistus vierailijan terveydestä heidän täyttäessään varausta. Heidän tulee täyttää kaikki kohdat voidakseen tehdä varauksen. Voit muotoilla kohdat COVID-19 -menettelynne mukaisiksi.',
  lobbyServicesOpeningHoursHelp:
    'Aulapalveluiden aukioloajat näytetään rakennnuksen tiedoissa. Tämä ei määritä tilojen aukioloaikoja varaamista varten, vaan kertoo vierailijoille, milloin aulapalveluita on saatavilla.',
  lobbyUsergroupHelp:
    'Tämän käyttäjäryhmän jäsenet, joilla on myös oikeus hallita varauksia, saavat nähdä ja hallita varauksia tässä lokaatiossa, vaikkeivät kuuluisi samaan organisaatioon.',
  contactInfoHelp:
    'YHTEYSTIETO näytetään rakennuksen tiedoissa. Näin vierailijat voivat ottaa teihin yhteyttä, jos heillä on kysyttävää tai he haluavat keskustella lisäpalveluista.',
  contactEmailHelp:
    'Jos haluat saada sähköpostitse ilmoituksia varauksista ja peruutuksista, ruksaa alla oleva valinta. Voit lisätä useampia sähköpostiosoitteita.',
  openingHoursHelp:
    'AUKIOLOAJAT määrittelevät, milloin tämän lokaation tilat ovat varattavissa sovelluksessa.',
  locationPhotoHelp:
    'App-kuva näytetään sovelluksessa. Hyvä app-kuva lokatiosta on kuva rakennuksesta tai sen rakennuksen sisäänkäynnistä, jossa tila sijaitsee.\nWeb 1 -kuva näytetään lokaatiolistauksessa ja -näkymässä Spacentin nettisivuilla. Vapaehtoinen web 2 -kuva näytetään lokaationäkymässä Spacentin nettisivuilla.',
  blockReservingOnNationalHolidays: 'Estä varaukset kansallisina vapaapäivinä',
  publicHoliday: 'Kansallinen vapaapäivä',
  createWebPage: 'Luo julkinen web-sivu',

  // amenities editor -- admin
  forExtraCost: 'Saatavilla lisämaksusta',
  locationSpecificInfo: 'Lisätiedot',
  print_scan_copy: 'Tulostus / skannaus / kopiointi',
  coffee_tea: 'Kahvi ja tee',
  restaurant: 'Ravintola rakennuksessa',
  cafe: 'Kahvila rakennuksessa',
  hand_sanitizer: 'Käsien desinfiointi',
  enhanced_cleaning: 'Tehostettu siivous',
  parking: 'Nimetty parkkipaikka',
  accessible: 'Saavutettava',
  lockers: 'Säilytyslokerot',
  reception: 'Vastaanotto',
  phone_booth: 'Puhelinkoppi',
  gym: 'Kuntosali',
  adjustable_table: 'Säädettävä pöytä',
  monitor: 'Ulkoinen näyttö',
  silent_space: 'Hiljainen tila',
  whiteboard: 'Valkotaulu',
  projector: 'Projektori/videotykki',
  videoconference: 'Videokonferenssi',
  furnished: 'Kalustettu (työhuoneet)',
  pet_friendly: 'Lemmikkiystävällinen',
  shower: 'Suihku',
  bike_parking: 'Pyöräparkki',

  // image uploader -- admin
  marketingImageCover: 'MAINOS',
  imageUploaderCover: 'APP',
  imageUploaderNew: 'UUSI',
  imageUploaderAdded: 'Uusi. Klikkaa poistaaksesi.',
  imageUploaderRemoved: 'Poistettu. Klikkaa lisätäksesi takaisin.',
  imageUploaderNoChange: 'Nykyinen kuva. Klikkaa poistaaksesi.',
  imageUploaderUploading: 'Ladataan...',
  imageUploaderAlt: 'Muokkaa alt-tekstejä',
  imageUploaderDropHere:
    'Raahaa kuvatiedostot tähän tai klikkaa valitaksesi tiedosto tietokoneeltasi',
  imageUploaderDragToReorder: 'Uudelleenjärjestä kuvia vetämällä.',
  imageUploaderUnsavedChangesInfo: 'Muutoksesi näkyvät tallentamisen jälkeen',
  imageUploaderUnsavedChanges: 'Tallentamattomia muutoksia',
  imageUploaderDownloadImage: 'Lataa alkuperäinen kuva',
  setMarketingImage: 'Aseta mainoslaatuiseksi kuvaksi',
  unsetMarketingImage: 'Poista mainoskuvalaatumerkintä',

  // announcements -- admin
  sendAnnouncement: 'Lähetä tiedote',
  selectBuildings: 'Valitse rakennukset',
  noBuildings: 'Ei rakennuksia valittavissa',
  users: 'käyttäjää',
  announcementsToOwnEmail:
    'Lähetämme tiedotteen myös sinun omaan sähköpostiosoitteeseesi',
  announcementSubject: 'Otsikko',
  announcementMessage: 'Viesti',
  announcementConfirmation:
    'Lähetä tiedote kaikille valittujen rakennusten käyttäjille?',

  // feedback -- admin
  locationFeedback: 'Palautteet lokaatiolle',
  openFeedback: 'Kirjalliset palautteet',
  noOpenFeedback: 'Tälle rakennukselle ei ole annettu kirjallisia palautteita',
  noFeedbacksToShow: 'Ei näytettäviä palautteita',
  hideRatingFeedback: 'Piilota arvostelut ilman kirjallista palautetta',
  ratings: 'Arviot',
  ratingsTooltip: 'Annettujen 1-5-arvioiden lukumäärät tälle lokaatiolle',
  rating: 'Arvio',
  numberOfFeedback: 'Annettuja arvioita kpl',
  respond: 'Vastaa',
  response: 'Vastaus',
  respondToFeedbackTitle: 'Vastaa palautteeseen',
  readyToSendConfirm: 'Oletko valmis lähettämään vastauksen?',
  send: 'Lähetä',
  aboutFeedbackResponseRecipient: 'Tietoa vastauksen lähetyksestä',
  aboutFeedbackResponseDescription: `Vastaanottaja saa tämän viestin sähköpostitse ja heidän mahdollinen vastaus siihen menee Spacentin tuen kautta. Tämä mahdollistaa sen, että voit hostina reagoida asiakaspalautteisiin samalla kun suojelemme loppukäyttäjien yksityisyyttä.`,
  responseSent: 'Vastaus lähetetty',

  // building management -- admin
  updateBuildingManagements: 'Päivitä rakennusten tietoja',
  buildingName: 'Rakennuksen nimi',
  managementFetchError: 'Voi voi 😭. Rakennuksen tiedot eivät löytyneet',
  managementSaved: 'Rakennuksen tiedot tallennettu! 🎉',
  managementSaveError:
    'Voi oi 😭. Rakennuksen tietojen tallentaminen epäonnistui.',
  editBuildingManagement: 'Muokkaa rakennuksen tietoja',
  maintenanceEmails: 'Kiinteistöhuollon sähköpostiosoitteet',
  maintenanceEmail: 'Email',
  addEmail: 'Lisää',
  buildingDescription: 'Rakennuksen kuvaus',
  buildingDescriptionDescription:
    'Tämä kuvaus näytetään vierailijoille jotka selaavat rakennuksen tietoja. Jos rakennuksessa toimivan operaattorin lokaatiolle on oma kuvaus, tämä teksti näytetään sen jäljessä.',
  buildingDescriptionPlaceholder:
    'Rakennuksen huoltopalvelun yhteystiedot ja muut lisätiedot, jne.',
  buildingPhoto: 'Valokuva',
  buildingPhotoDescription:
    'Tämä kansikuva näytetään vierailijoille jotka selaavat rakennuksen tietoja, jos rakennuksessa toimivan operaattorin lokaatiolle ei ole lisätty erikseen kuvaa.',
  photos: 'Kuva',

  // spaces -- admin
  spaceAdded: 'Uusi tila luotu! 🎉',
  spaceAddError:
    'Oi voi 😭. Tilan luominen epäonnistui. Ole hyvä ja tarkista tilan tiedot.',
  spaceSaved: 'Tila on tallennettu! 🎉',
  spaceSaveError:
    'Oi voi 😭. Tilan tallentaminen epäonnistui. Ole hyvä ja tarkista tilan tiedot.',
  spaceHidingCleared: 'Tilan piilotus on peruutettu.',
  spaceHidingClearError:
    'Voi voi 😭. Tilan piilotuksen peruuttaminen epäonnistui.',
  spaceNotFoundError: 'Voi voi 😭. Tila ei löytynyt.',
  addNewSpace: 'Lisää tila',
  spaceName: 'Tilan nimi',
  integrations: 'Integraatiot',
  location: 'Lokaatio',
  capacity: 'Kapasiteetti',
  avgRating: 'Keskim arvio',
  hideFromCalendars: 'Estä varaukset',
  spacesHidden: 'Tilat on nyt piilotettu kalenterista.',
  spacesHiddenError: 'Voi voi 😭. Tilojen piilottamisessa tapahtui virhe',
  hideFromBookingCalendars: 'Estä varaukset väliaikaisesti',
  hideTimeSelection: 'Estoaika',
  hideSpaceSelection:
    'Väliaikaiset muutokset aukioloihin vaikuttavat seuraaviin tiloihin',
  hideSpaceLocationTimezoneNote:
    'Nykyinen aikavyöhykkeesi saattaa poiketa tilan käyttämästä aikavyöhykkeestä, mutta sinun ei tarvitse erityisesti huolehtia siitä. Jos tila on suljettuna esimerkiksi tiistaina 23. päivä paikallista aikaa, niin voit vain asettaa sen suljetuksi tiistaina 23. päivä, ja me huolehdimme että se menee oikein.',
  hideFrom: 'Alku',
  hideUntil: 'Loppu',
  hideDays: 'Päivien lukumäärä',
  hideAllDay: 'Piilota koko päiväksi',
  existingReservations: 'Olemassa olevia varauksia',
  viewAndCancelReservations: 'Näytä ja peru varaukset',
  hideSpaceDescription: 'Varauseston tai rajoitetun aukiolon syy',
  setCustomOpeningHours: 'Rajoita aukioloaikaa (älä sulje tilaa koko päiväksi)',
  openHoursNotExpandable:
    'Alla voit asettaa tilan aukiolon tietylle päivälle (eli valitse ne tunnit, kun tila on auki). Aukioloaikaa voi vain lyhentää, ei pidentää. ',
  offerSuffix: '-tarjous',
  name: 'Nimi',
  activity: 'Aktiivisuus',
  activityRecent: '6 kk sisällä',
  activityLong: 'Yli 6 kk sitten',
  activityNo: 'Ei varauksia',
  peopleManagementGuideTitle: 'Käyttäjien hallinnasta',
  peopleManagementGuideDomains:
    'Uudet käyttäjät lisätään automaattisesti kun he kirjautuvat käyttäen organisaation domainia ',
  peopleManagementGuideNewUsers:
    'Uudet käyttäjät lisätään Spacentin tuen toimesta.',
  peopleManagementDisabledUsers:
    'Poistetut käyttäjät voidaan uudelleen aktivoida Spacentin tuen toimesta.',
  noUsers: 'Ei käyttäjiä',
  pricing: 'Hinnoittelu',
  hours: 'Tunnit',
  days: 'Päivää',
  weeks: 'Viikkoa',
  months: 'Kuukautta',
  hour: 'Tunti',
  day: 'Päivä',
  week: 'Viikko',
  month: 'Kuukausi',
  perMonth: 'kuukaudessa',
  price: 'Hinta',
  editSpace: 'Muokkaa tilaa',
  spaceNamePlaceholder: 'Joustavat työpisteet',
  spaceDescriptionPlaceholder:
    'esim. kuvaus tilasta, varustelusta, pistokkeista, huonekaluista...',
  floor: 'Kerros',
  inheritFromLocation: 'Samat kuin rakennuksella',
  customHours: 'Vapaavalintaiset aukioloajat',
  customArrivalInfoForSpace: 'Tämän tilan omat saapumisohjeet',
  spaceDescription: 'Tilan kuvaus',
  spaceDescriptionDescription:
    'Jos mahdollista, lisää kuvaukset tilan vierailijoiden puhumilla kielillä.',
  spaceArrivalInfoDescription:
    'Tämän tilan erityiset saapumisohjeet. Tämän puuttuessa käytetään rakennuksen saapumisohjeita.',
  spaceTypeLabel: 'Tyyppi',
  spaceTypeCapacity: 'Tarjoa tila useampana työpisteenä',
  numberOfDesks: 'Työpisteiden lukumäärä',
  spaceTypeMeetingRoom: 'Tarjoa tila neuvotteluhuoneena',
  spaceTypeProjectRoom: 'Tarjoa työhuone pitkäaikaisiin varauksiin',
  spaceTypeOther: 'Muu tilatyyppi',
  spaceTypeTeam: 'Tarjoa tila tiimille',
  otherCapacity: 'Kapasiteetti',
  displayCapacity: 'Näytä kapasiteetti sovelluksessa',
  allowedDaysMax: 'Pisin sallittu varaus päivissä',
  meetingRoomCapacity: 'Tilan kapasiteetti',
  noPricingSet: 'Ei myyntihintaa',
  notSet: 'Ei asetettu',
  sellForFixedRate: 'Myy kiinteällä tuntihinnalla',
  priceExcludesTaxes: 'Hinta ei sisällä veroja',
  currency: 'Valuutta',
  availability: 'Saatavuus',
  freeForOwnOrganization:
    'Oman organisaatiosi jäsenet voivat varata tilan ilmaiseksi',
  spacePhotos: 'Valokuvat',
  spaceHidden: 'Näkyvyys',
  hiddenDueUnavailability: 'Varaukset estetty väliaikaisesti',
  spaceHiddenDescription: 'Varaukset on estetty kokonaan seuraavina päivinä:',
  spaceCustomOpeningHours:
    'Poikkeavat aukioloajat (tila on auki ainoastaan tällä välillä):',
  offer: 'Tarjous',
  offering: 'Tarjooma',
  fieldsMissing: 'Jotkin vaaditut kentät ovat vielä tyhjiä',
  saveAndPublish: 'Tallenna ja julkaise',
  saveAndSendForReview: 'Tallenna tarkistettavaksi',
  hourlyPricingDisabledForCapacity:
    'Jos haluat muuttaa pöytäpaikkojen hinnoittelua, ota meihin yhteyttä!',
  dateRangePickerFrom: 'Mistä',
  dateRangePickerTo: 'Mihin',
  dateRangePickerUntil: 'Mitä ennen',
  reservationBuffer: 'Tauko käyttöjen välillä',
  reservationBufferBefore: 'Tauko ennen käyttöä (minuutteja)',
  reservationBufferAfter: 'Tauko käytön jälkeen (minuutteja)',
  reservationBufferHelp:
    'Jos tila pitää valmistella ennen käyttöä tai siivota sen jälkeen, voit asettaa ajan jona tilaa ei voi varata ennen ja jälkeen sen käytön',
  status: 'Status',
  live: 'Live',
  hidden: 'Pois käytöstä',
  draft: 'Luonnos',
  inReview: 'Käsittelyssä',
  internal: 'Sisäinen',
  'hidden-from-calendars': 'Varaukset estetty',
  deleted: 'Poistettu',
  confirmDeletion: 'Oletko varma, että haluat poistaa tilan?',
  confirmPublishingStatusChange:
    'Oletko varma, että haluat muuttaa tilan näkyvyyttä?',
  saveAsADraft: 'Tallenna luonnoksena',
  duplicate: 'Kopioi',
  offerActive: 'Aktiivinen',
  offerLabel: 'Tunniste',
  offerPricing: 'Hinnoittelu',
  offeringReservableFor: 'Ketkä voivat varata',
  offerPricingAndAvailability: 'Hinnoittelu ja saatavuus',
  offerNotes: 'Huomiot',
  consumerSalesTitle: 'Kuluttajamyynti',
  sellForConsumers: 'Salli kuluttajien tehdä varauksia tähän tilaan',
  sellForConsumerClarification: 'Tällä ei ole vaikutusta hinnoitteluun',
  offerFree: 'Ilmainen',
  offerVolume: 'Hinnoittelu aikayksiköittäin',
  offerVolumeTooltip:
    'Esim. Varaus joka kestää 2 vk ja 3 pv maksaa 2 * viikkohinta + 3 * päivähinta.',
  offerTieredByUnit: 'Porrastettu, hinta per valittu aikayksikkö',
  offerTieredByUnitTooltip:
    'Esim. Porrastettuna (1 t, 20 €) ja (3 t, 15 €), varaus joka kestää 2 tuntia maksaa 40 €, ja 3 tuntia 45 €.',
  offerTieredByLength: 'Porrastettu, kokonaishinta keston mukaan',
  offerTieredByLengthTooltip:
    'Esim. Porrastettuna (1 t / 20 €) ja (3 t / 50 €), varaus jonka kesto on 2 t osuu 3 t portaaseen ja maksaa siten 50 €.',
  offerTieredHoursFrom: 'Mistä',
  offerTieredHoursTo: 'Mihin',
  offerCreateNewPricing: 'Luo uusi hinnoittelu',
  offerHourlyPrice: 'Veroton tuntihinta',
  offerDailyPrice: 'Veroton päivähinta',
  offerWeeklyPrice: 'Veroton viikkohinta',
  offerMonthlyPrice: 'Veroton kuukausihinta',
  units: 'Yksikköä',
  offerRestrictedEditingMessage:
    'Tätä hinnoittelua käsitellään hieman eri tavalla taustajärjestelmässä, siten tietyt muokkausmahdollisuudet ovat estetty. Otathan meihin yhteyttä mikäli haluat tehdä näihin muutoksia!',
  offerTaxExclusivePricePer: 'Veroton hinta per',
  offerAddNewTier: 'Lisää uusi porras',
  offerDeletePricingConfirmationMessage:
    'Haluatko varmasti poistaa tämän hinnoittelun?',
  offerReservationLimits: 'Varausrajat',
  offerMaxReservationHours: 'Pisin varausaika tunneissa',
  offerMinReservationHours: 'Lyhin varausaika tunneissa',
  offerMaxReservationDays: 'Pisin varausaika päivissä',
  offerMinReservationDays: 'Lyhin varausaika päivissä',
  offerMaxReservationHoursPerDay: 'Maksimivarausaika tunneissa per päivä',
  offerMaxUpcomingReservationsPerUser:
    'Suurin sallittu aktiivisten varausten määrä per käyttäjä',
  offerMaxReservationHoursBeforeStart:
    'Tunteja enintään ennen varauksen alkamista',
  offerMaxReservationTooltip: `Jos haluat rajoittaa aikaisinta aikaa jolloin käyttäjä voi tehdä varauksen ennen sen alkamista`,
  offerMinReservationHoursBeforeStart:
    'Tunteja vähintään ennen varauksen alkamista',
  offerMinReservationTooltip: `Jos haluat rajoittaa viimeisintä aikaa jolloin käyttäjä voi tehdä varauksen ennen sen alkamista`,
  offerPricingSpecialMeaning:
    'Tätä hinnoittelua käsitellään eri tavalla taustajärjestelmässä, ja siten sen muokkaaminen on estetty muilta kuin super-admineilta.',
  showAllPricings: 'Näytä kaikki hinnoittelut',
  addNewPricing: 'Lisää uusi hinnoittelu',
  offerFreeForOrganizationMembers: 'Ilmainen organisaation omille jäsenille',
  setFixedHourlyPricing: 'Aseta kiinteä tuntihinta',
  setSimplePricing: 'Aseta yksinkertaiset hinnat aikajaksolle',
  setCustomPricing: 'Käytä mukautettua tai jaettua hinnoittelua',
  setPricePerAccess: 'Aseta päiväkohtainen hinta',
  taxExclusivePricePerAccess: 'Veroton päiväkohtainen hinta',
  pricingUpdated: 'Hinnoittelu päivitetty',
  pricingHasBeenChanged: 'Muutokset hinnoitteluun tallennettu',
  ohNo: 'Voi ei',
  somethingWentWrongWithThePricing:
    'Jotain meni hintojen päivityksessä pieleen.',
  pricingHasBeenDeleted: 'Hinnoittelu poistettu',
  pricingHasBeenRemovedFromSpaces:
    'Hinnoittelu ei kohdistu enää mihinkään tilaan ja se on poistettu.',
  somethingWentWrongDeletingPricing:
    'Jotain meni pieleen poistaessa hinnoittelua.',
  offerChangesAffectFollowingSpaces:
    'Mahdolliset hintamuutokset astuvat voimaan välittömästi seuraaville tiloille.',
  offerChangesAffectsNoSpaces:
    'Hinnoitteluun ei ole vielä liitetty yhtään huonetta.',
  note: 'Huomioi',
  offerSpaceForMembershipHolders:
    'Näytä tila "Kuuluu jäsenyyteen"-tagilla käyttäjille, joilla on Coworking-passi',
  connectedSpaces: 'Yhdistetyt tilat',
  connectedSpacesTooltip:
    'Jos jokin yhdistetyistä tiloista on varattu, myös muut yhdistetyt tilat on varattu.',
  connectedReservation: 'Varaus on tehty yhdistettyyn tilaan',
  duplicateConfirmationTitle: 'Oletko varma että haluat monistaa tilan?',
  duplicateConfirmationText:
    'Tämä luo uuden tilan ja kopioi siihen tämän tilan tiedot.',
  setReservationLimits: 'Aseta varausrajoituksia',
  offerLimitationsOverrideWarning:
    'Näiden rajoitusten lisääminen yliajaa huoneen oletusvarausrajoitukset, jos sellaisia on.',
  earliestTimeToMakeReservation: 'Aikaisin aika tehdä varaus',
  latestTimeToMakeReservation: 'Viimeinen aika tehdä varaus',
  someHoursBeforeStart: 'Joitain tunteja ennen alkua',
  someDaysBeforeStart: 'Päiviä ennen varauksen alkua',
  previousDayAtTime: 'Edellisenä aukiolopäivänä ennen kello...',

  // People management -- admin
  done: 'Tehty!',
  nowReactivated: 'on nyt aktivoitu.',
  nowRemoved: 'on nyt poistettu.',
  removeUserTitle: 'Poista käyttäjä?',
  removeUserBody:
    'Oletko varma, että haluat poistaa käyttäjän? Käyttäjä kirjataan välittömästi ulos eikä hän voi tehdä varauksia',
  orgHasNoUsers: 'Organisaatiossasi ei ole yhtään käyttäjää',
  disabledUsers: 'Poistetut käyttäjät',

  // user groups -- admin
  addUsergroup: 'Lisää käyttäjäryhmä',
  usergroupCreated: 'Uusi käyttäjäryhmänne on luotu! 🎉',
  usergroupCreateError: 'Voi voi 😭. Käyttäjäryhmänne luonti ei onnistunut...',
  usergroupSaved: 'Käyttäjäryhmä on tallennettu! 🎉',
  usergroupSaveError:
    'Voi voi 😭. Käyttäjäryhmän tallennuksessa tapahtui virhe.',
  inviteListFormatError:
    'Listassa oli virheellinen sähköpostiosoite tai osoitteet eivät olleet erotettu pilkulla',
  invitesSent: 'Kutsut lähetetty 🎉',
  invitesSendError: 'Kutsujen lähetyksessä tapahtui virhe 😭',
  membersOfGroup: 'Jäsenet ryhmässä',
  editUsergroup: 'Muokkaa ryhmää',
  inviteAddresses: 'Kutsuttavien sähköpostiosoitteet',
  sendInvites: 'Lähetä kutsut',
  pendingInvites: 'Lähetetyt kutsut',
  usersInGroup: 'Ryhmän käyttäjät',
  tieredByLength: 'Porrastettu hinnoittelu',
  totalTaxExclusivePrice: 'Veroton kokonaishinta',
  usergroupChangesImmediate:
    'Muutokset käyttäjäryhmään tulevat voimaan välittömästi.',
  usergroupCreateImmediate: 'Käyttäjäryhmä luodaan välittömästi.',
  lobbyUsergroupAssociationSaveDelayed:
    'Yhteys lokaatioon astuu voimaan vasta, kun tallennat päälomakkeen.',
  detachUsergroup: 'Irrota käyttäjäryhmä',
  selectExistingUsergroup: 'Valitse olemassaoleva ryhmä',
  lobbyUsergroup: 'Aulapalvelu -käyttäjäryhmä',
  byVolumeEditorFillBlanks: 'Laske puuttuvat hinnat',

  // settings -- admin
  yourAccount: 'Tietosi',
  yourOrganization: 'Organisaatiosi',
  logout: 'Kirjaudu ulos',

  // checkin
  checkIn: 'Ilmoita saapumisesta',
  checkInHeader: 'Kerro järjestäjälle, että olet saapunut paikalle',
  checkInAddress: 'Tilaisuuden osoite',
  checkInFormEmailPlaceholder: 'name@example.com',
  checkInPhone: 'Puhelinnumerosi maakoodilla (esim. +358987654)',
  checkInFormPhoneError: 'Syötä voimassa oleva puhelinnumero',
  checkInFormNameError: 'Täytä vielä nimesi',
  checkInErrorAlreadyCheckedIn:
    'Olet jo ilmoittanut saapumisesta tällä sähköpostilla',
  checkInErrorInvalid: 'Ilmoittautuminen ei onnistunut',
  checkInSuccessHeader: 'Kiitos!',
  checkInSuccessDesc: `Olet nyt ilmoittanut saapumisestasi! Olemme lähettäneet järjestäjälle tiedon saapumisestasi ja voit olla häneen yhteydessä mahdollisissa ongelmatilanteissa`,
  checkInNoReservation:
    'Voit kirjautua sisään neuvotteluhuoneeseen kokouspäivänä.',
  checkinSmsText: `Spacentin kautta varattuun tilaan kutsumasi vieras on ilmoittanut saapuneensa!`,

  // login -- admin
  loginToAdmin: 'Kirjaudu sisään Spacent-hallinnointiin',
  adminLoginUnauthorized:
    'Ikävä kyllä sinulla ei ole pääsyoikeuksia Spacent-hallinnointiin.',
  adminLoginError: 'Sisäänkirjautuminen epäonnistui',
  adminLoginEmailAuth:
    'Lähetimme sinulle linkin sisäänkirjautumiseen! Klikkaa linkkiä sähköpostissasi tai kopioi se selaimeesi. Sähköpostin saapumiseen voi mennä useita minuutteja.',
  adminLoginEmail: 'Kirjoita sähköpostiosoitteesi',
  adminLoginTryAgain: 'Ole hyvä ja yritä kirjautua uudelleen:',
  adminLoginRetryInstructions:
    'Huomaa että vain uusin saamasi linkki on voimassa. Sähköpostin saaminen voi kestää useamman minuutin.',
  adminWelcome: 'Tervetuloa Spacent-hallintaan',
  login: 'Kirjaudu sisään',
  adminErrorContact: 'Jos tämä oli virhetilanne, ole hyvä ja ota yhteyttä',
  clickToRetry: 'Klikkaa tästä yrittääksesi uudelleen',

  // login
  timeToCheckEmail: 'Aika kurkata sähköpostiisi!',
  emailSent:
    'Lähetimme sinulle koodin vahvistaaksemme sähköpostiosoitteesi. Syötä lähetetty koodi alla olevaan kenttään.',
  verificationCode: 'Vahvistuskoodi',
  loginAttemptFailed:
    'Kirjautumisyritys epäonnistui. Varmistathan, että sinulla on oikea koodi tai yritä kirjautumista uudelleen.',

  // invites -- admin
  inviteStart: 'Hei! Aloitetaan Spacent-palvelun käyttö',
  inviteWelcome: 'Tervetuloa hajautettuun työpaikkaasi!',
  invitedAccount: 'Kutsuttu käyttäjätili',
  inviteMicrosoftSso: 'Jatka Microsoftin SSO-sisäänkirjautumisella',
  inviteGoogleSso: 'Jatka Google SSO-sisäänkirjautumisella',
  inviteEmailAuth: 'Jatka sähköpostilinkillä',
  inviteCheckEmail: 'Kastso sähköpostiisi!',
  inviteCheckEmailDescription:
    'Katso sähköpostiisi! Sisäänkirjautumislinkki on lähetetty sähköpostiisi:',
  inviteNotFound: 'Ikävä kyllä kutsuasi ei löytynyt.',
  inviteError:
    'Kutsusi käsittelyssä tapahtui virhe. Ole hyvä ja yritä uudelleen myöhemmin',
  inviteDoneExistingHeader: 'Valmista tuli!',
  inviteDoneExistingContent:
    'Organisaatiosi on liittynyt Spacent-palveluun. Aloita käyttö lataamalla Spacent-sovellus puhelimeesi. Kutsuhinnoittelua varten käytä kutsusi sähköpostiosoitetta:',
  inviteDoneNewOrganization: 'Organisaatio luotu',
  inviteDoneNewHeader: 'Tervetuloa Spacent-yhteisöön',
  inviteDoneNewContent:
    'Olemme nyt luoneet käyttäjätilisi ja lisänneet sen organisaation Spacent-palveluun. Aloita käyttö lataamalla Spacent-sovellus puhelimeesi. Aloittaaksesi hajautetun työpaikan käytön, kirjaudu sisään sähköpostiosoitteellasi:',
  createAccount: 'Luo tili',

  // onboarding -- admin
  onboardingLoading: 'Ladataan käyttöönottoa...',
  goBack: 'Palaa edelliselle sivulle',
  onboardingNotFound: 'Ikävä kyllä kesken ollut käyttöönotto ei löytynyt.',
  onboardingError: 'Tapahtui virhe.',
  onboardingDetails: 'Tiedot',
  onboardingSummary: 'Yhteenveto ja maksutapa',
  onboardingDownload: 'Lataa sovellus',
  onboardingDownloadTooltip: 'Täytä edellisten kohtien tiedot ensin',
  introTitle: 'Siirry toimiston tulevaisuuteen',
  introLocationsFI: 'työpaikkalokaatiota Suomessa',
  introLocationsSE: 'työpaikkalokaatiota Ruotsissa',
  introLocationsCA: 'työpaikkalokaatiota Kanadassa',
  introDesks: 'Rajattoman työpistepaikkojen käytön',
  introShare: 'Rajattoman työpistepaikkojen käytön kaikille työntekijöillenne',
  introRooms: 'Kertamaksulliset neuvotteluhuoneet ja työhuoneet',
  introPriceTitle: 'Hinta (per henkilö)',
  onboardingDedicatedSubscriptionPriceUnit: 'Per työntekijä',
  onboardingCorporateSubscriptionPriceUnit: 'Per 20 jaettavaa päiväpassia',
  onboardingAddDedicatedUsers: 'Täytä tilauksen käyttäjät',
  purchaseSummary: 'Tilauksen yhteenveto',
  onboardingPerMonth: 'kuukaudessa',
  introCorporateAccess: 'Kaikki Spacent työtilat käytössäsi',
  introCorporateAccessFI:
    'Kaikki Spacent Suomen työtilat organisaatiosi käyttöön',
  introCorporateAccessSE:
    'Kaikki Spacent Ruotsin työtilat organisaatiosi käyttöön',
  introCorporateAccessCA:
    'Kaikki Spacent Kanadan työtilat organisaatiosi käyttöön',
  introCorporateEmployees: 'Spacent kaikille työntekijöillesi',
  yourInfo: 'Tietosi',
  subscriptionSelectType: 'Valitse jäsenyyden tyyppi',
  dedicatedMembership: 'Henkilökohtainen coworking-passi',
  corporateMembership: 'Jaettu coworking-passi',
  payPerUse: 'Erikseen maksettava',
  amountOfMemberships: 'Käyttäjien määrä',
  membershipTerminationPeriodTitle: 'Irtisanomisaika',
  membershipTerminationPeriodContent: '3 kuukautta',

  loginEmailDomain: 'Käyttäjien rajaus',
  loginEmailDomainDescription: `Kaikki joilla on sähköpostiosoite tässä domainissa saavat käyttää Coworking-passia`,
  loginEmailDomainTitle: `Organisaationi sähköpostidomain (osoitteelle me@example.com tämä on example.com)`,
  loginEmailManualDescription: `Asiakas palvelu auttaa minua määrittelemään tietyt käyttäjät`,
  loginEmailManualDetails:
    'Otamme sinuun yhteyttä 1-2 arkipäivän kuluessa niin sovitaan paras tapa rajata organisaatiosi käyttäjät.',
  subscriptionUsersCount: 'Käyttäjiä: ',
  subscriptionTotal: 'Summa: ',
  subscriptionMembership: 'Henkilökohtainen coworking-passi',
  corporateSubscriptionMembership: 'Jaettu coworking-passi',
  subscriptionSalesEmail: 'Spacent-myyntiin',
  subscriptionCustomize: 'niin saat ratkaisun tiimillesi.',
  subscriptionAdd: 'Lisää',
  subscriptionRemove: 'Poista',
  subscriptionEmailError: 'Täytä validi sähköpostiosoite',
  onboardingInfoError: 'Tarkista, että olet täyttänyt kaikki tiedot.',
  organizationInfo: 'Organisaation tiedot',
  addressStreet: 'Katuosoite',
  addressPostalCode: 'Postinumero',
  addressCity: 'Postitoimipaikka',
  addressCountry: 'Maa',
  paymentIAccept: 'Hyväksyn seuraavat ehdot:',
  paymentTermsAndConditions: 'Spacent-käyttöehdot',
  paymentUserTerms: 'Käyttäjän käyttöehdot',
  paymentPrivacyPolicy: 'Yksityisyyskäytäntö',
  summary: 'Yhteenveto',
  startDate: 'Valitse aloituspäivä',
  payment: 'Maksutapa',
  emailInvoice: 'Lasku sähköpostitse',
  electronicInvoice: 'E-lasku',
  order: 'Tilaa',
  contact: 'Yhteys',
  summaryIncomplete: '(kesken)',
  otherMembers: 'Muut jäsenet',
  fillDetailsForSummary: 'Täytä edeltävät tiedot yhteenvetoa varten',
  onboardingDoneTitle: 'Tervetuloa toimiston tulevaisuuteen!',
  onboardingDoneSuccess: 'Olet nyt rekisteröitynyt Spacent-palveluun.',
  onboardingDoneDownload:
    'Lataa Spacent-sovellus ja kirjaudu sisään sähköpostillasi',
  onboardingDoneFindSpace: 'löytääksesi täydellisen työtilasi.',
  onboardingDoneEmail:
    'Olemme myös lähettäneet sähköpostiisi lisätietoa jäsenyydestäsi.',
  identifier: 'Y-tunnus',
  onboardingPaymentMethodNote:
    'Valittua maksutapaa käytetään organisaatiosi kaikkiin kuluihin, mukaan lukien jäsenten tekemät kertamaksulliset tilavaraukset.',
  onboardingRegionFI: 'Suomi',
  onboardingRegionSE: 'Ruotsi',
  onboardingRegionCA: 'Kanada',
  onboardingOrganizationLocation: 'Missä yrityksesi sijaitsee?',
  onboardingSelectPlan: 'Valitse jäsenyys',
  onboardingAccountDetails: 'Omat tietosi',
  onboardingOrganizationDetails: 'Organisaation tiedot',
  onboardingStartWithYourInfo: 'Omat tietosi',
  unitPrice: 'Yksikköhinta',
  basePrice: 'Perushinta',
  discountHeader: 'Alennus',
  forOnePerson: 'Yhdelle hengelle',
  forOneToTwentyUsers: '1-5 käyttäjälle',
  startingFrom: 'Alkaen',
  fillTheFormPrefixGB: `Jos yrityksessäsi on yli 5 henkilöä, tai haluat tarpeisiisi kustomoidun tilaratkaisun,`,
  fillTheFormPrefix: `Jos yrityksessäsi on yli 10 henkilöä, tai haluat tarpeisiisi kustomoidun tilaratkaisun,`,
  fillTheForm: 'täytä lomake',
  onboardingFormUrl: 'https://offers.spacent.com/fi/contact',
  fillTheFormSuffix: `ja myyntimme on sinuun yhteydessä.`,

  onboardingWelcomeStep: 'Tervetuloa',
  onboardingInvoicingDetailsStep: 'Laskutustiedot',
  onboardingDoneStep: 'Valmis',
  onboardingFinishedTitle: 'Kiitos, voit ottaa nyt sovelluksen käyttöösi!',
  imAnIndividual: 'Olen yksityishenkilö',

  // Redeem codes
  discountCode: 'Alennuskoodi',
  redeemDiscountCode: 'Käytä alennuskoodi',
  discountCodeInstructions:
    'Jos sinulla on alennuskoodi, ole hyvä ja syötä se alle',
  discountAlreadyApplied: 'A discount has already been applied.',
  sendDiscountCode: 'Lähetä koodi',
  redeemCodeAddToUsergroupSuccess:
    'Nyt sinulla on käytössäsi enemmän ominaisuuksia Spacentissa.',
  redeemCodeChangeOrganizationSuccess:
    'Nyt sinulla on käytössäsi enemmän ominaisuuksia Spacentissa.',
  redeemCodeDiscount: 'Sinulla on nyt käytössä alennettu hinta.',
  redeemCodePersonalMembershipDiscountRegionMismatch:
    'Tämä alennuskoodi ei ole käytössä sinun sijainnillesi.',
  redeemCodeInternalError:
    'Tapahtui sisäinen virhe. Spacentin tekninen tuki on saanut virheestä ilmoituksen.',
  redeemCodeNotFound:
    'Emme tunnistaneet tätä koodia. Ole hyvä ja tarkista kirjoititko sen oikein.',
  redeemCodeUsed: 'Käytit koodin jo aiemmin. Kaikki on kunnossa!',
  redeemCodeNoDiscountForExistingSubscription:
    'Tämän alennuskoodin voi käyttää vain ennen tilauksen aloitusta.',

  vat: 'ALV',
  // TODO for canada but update when more types
  sales_tax: 'HST',

  // emails -- admin
  emailReservationFrom: 'alkaen: ',
  emailReservationUntil: 'loppuen: ',
  emailReservationView: 'Löydät varauksen täältä: ',
  emailReservationManagement: 'Spacent-hallintatyökalu',
  emailReservationContact: 'Jos sinulla on kysymyksiä, voit ottaa yhteyttä',
  emailBestRegards: 'Parhain terveisin,',
  emailSpacentTeam: 'Spacent-tiimi',

  emailNewReservationTitle: 'Uusi varaus!',
  emailCancelReservationTitle: 'Varaus on peruttu',

  emailYouWereAddedToGroup: 'Sinut on lisätty Spacent-ryhmään',
  emailMoreWorkspaces: 'Nyt sinulla on lisää Spacent-työtiloja käytössäsi!',
  emailGreatCompany: 'Olet hyvässä seurassa.',
  emailHello: 'Hei',
  emailAddedToGroup: 'Sinut on lisätty uuteen käyttäjäryhmään.',
  emailOrganizationAddedToGroup: 'on lisännyt sinut käyttäjäryhmään',
  emailMoreWorkspacesAvailable:
    'Tämä tarkoitta sitä että nyt sinulle on entistä enemmän työpisteitä tarjolla Spacent-sovelluksessa.',

  emailWelcomeToBook: 'Tervetuloa varaamaan Spacent-työtiloja!',
  emailWelcomeTitle:
    'Sinut on kutsuttu toimiston tulevaisuuteen Spacentin avulla!',
  emailWelcomeSpacentProvidesSolution:
    'tarjoaa joustavan ratkaisun hybridityöskentelyyn - voit tehdä töitä missä ja milloin vain.',
  emailWelcomeOrganizationHasInvited:
    'on kutsunut sinut Spacentiin. Spacent-sovelluksella voit löytää ja varata työpisteen milloin vain.',
  emailWelcomeGetStarted: 'Aloitetaan:',
  emailWelcomeGetApp: 'Lataa Spacent-sovellus.',
  emailWelcomeLogin: 'Kirjaudu sisään sähköpostiosoitteellasi',
  emailWelcomeFindYourSpot:
    'Etsi sopivia tiloja ja tee ensimmäinen varauksesi!',

  emailOnboardedAdminWelcome: 'Tervetuloa Spacentiin!',
  emailOnboardedAdminTitle:
    'Onnittelut liittymisestäsi toimiston tulevaisuuteen Spacentin kanssa!',
  emailWelcome: 'Tervetuloa',
  emailSpacentProvides:
    'tarjoaa joustavan ratkaisun dynaamiseen työpaikkaan - voit tehdä töitä milloin ja missä vain.',
  emailGetStarted: 'Aloitetaan',
  emailOnboardedAdminReady: 'ja olet valmis.',
  emailOnboardedAdminOrganization: 'Organisaatiosi',
  emailOnboardedAdminContact:
    'Spacent-jäsenyytesi pystyy kasvamaan organisaatiosi kanssa ja mukautumaan tarpeisiisi. Voit aina muokata tai peruuttaa organisaatiosi Spacent-jäsenyyden ottamalla yhteyttä',
  emailOnboardedAdminView:
    'Voit katsoa ja päivittää organisaatiosi laskutustiedot täällä: ',
  emailOnboardedAdminManagement: 'Spacent-hallintatyökalu',
  emailOnboardedAdminInvoicing: 'Laskutus',
  emailOnboardedAdminInvoicingDescription:
    'Kuukausijäsenyys laskutetaan tilauksen mukaan:',
  emailOnboardedAdminInvoicingItem: 'Tuote',
  emailOnboardedAdminInvoicingQuantity: 'Määrä',
  emailOnboardedAdminInvoicingPrice: 'Hinta',
  emailOnboardedAdminInvoicingMembership: 'Spacent coworking-passi',
  emailOnboardedAdminInvoicingTotal: 'Summa',
  emailOnboardedAdminInvoicingDiscount: 'Alennus',
  emailOnboardedAdminDescription:
    "Jäsenyyteen kuuluu kaikki tilat jotka on merkitty 'Kuuluu jäsenyyteen'. Jos organisaatiosi jäsenet varaavat tiloja jotka eivät kuulu jäsenyyteen, hinta näytetään käyttäjälle ja nämä varaukset laskutetaan organisaatioltasi kuukausijäsenyyden lisäksi.",
  emailOnboardedAdminTerms:
    'Liittyessäsi Spacentiin olet hyväksynyt seuraavat ehdot:',
  emailOnboardedAdminConfirmation:
    'Jos sinä et tehnyt tätä liittymistä, ole hyvä ja ota yhteyttä',

  emailOnboardingUserWelcome: 'Tervetuloa varaamaan työtiloja Spacentin kanssa',
  emailOnboardingUserTitle:
    'Tervetuloa Spacentiin ja onnittelut toimiston tulevaisuuteen liittymisestä!',
  emailOnboardingUserUserAtOrganization: 'organisaatiossa',
  emailOnboardingUserInvitedYou: 'on kutsunut sinut Spacent-alustaan.',
  emailOnboardingUserSpacentProvides:
    'tarjoaa joustavan ratkaisun dynaamiseen työpaikkaan - Spacent-sovelluksella voit löytää ja varata työpisteen milloin ja mistä vaan sen tarvitset.',
  emailOnboardingUserDescription:
    "Spacent-jäsenyytesi kattaa kaikki tilat jotka on merkitty 'Kuuluu jäsenyyteen'. Erikseen hinnoitellut tilat laskutetaan näytetyn hinnan mukaan organisaatiolta",
  emailOnboardingUserMoreInfo:
    'Lisätietoa Spacent-jäsenyydestä löydät osoitteesta',
  emailOnboardingUserContact:
    'Jos sinulla on kysyttävää, ole hyvä ja ota yhteyttä',

  emailAuthSubject: 'Kirjaudu Spacent-palveluun',
  emailVerificationSubject:
    'Sähköpostiosoitteen vahvistaminen Spacent-palveluun',
  emailAuthTitle: 'Spacent kirjautumistietosi :)',
  emailAuthGreeting: 'Hei',
  emailAuthLoginLink:
    'Tässä on kertakäyttöinen kirjautumislinkkisi. Linkistä sinut uudelleenohjataan Spacent-palveluun kirjautuneena käyttäjänä.',
  emailAuthCodeInfo:
    'Tässä on kertakäyttöinen kirjautumiskoodisi. Kun syötät sen pyydettyyn kenttään, sinut kirjataan sisään Spacent-palveluun. Koodi on voimassa yhden tunnin.',
  emailVerificationCodeInfo:
    'Vahvista sähköpostisi syöttämällä koodisi sitä pyytävään kenttään. Koodi on voimassa yhden tunnin.',
  emailVerificationTitle: 'Spacent vahvistuskoodisi :)',
  emailAuthNote: 'HUOMAA',
  emailAuthAppPressLoginLink:
    'Varmista, että klikkaat kirjautumislinkkiä puhelimessasi, jossa Spacent sovellus on asennettuna!',
  emailAuthAppPressHereToLogin:
    'Paina tästä kirjautuaksesi sisään puhelimessasi',
  emailAuthWebPressHereToLogin: 'Klikkaa tästä kirjautuaksesi',
  emailAuthIfButtonDoesNotWork:
    'Mikäli nappi ei jostain syystä toimi, niin voit myös kopioida alla olevan linkin selaimesi osoitepalkkiin. Tämä kirjautumislinkki on voimassa tunnin ajan.',
  emailAuthContactUs: 'Voit aina ottaa meihin yhteyttä osoitteessa: ',
  emailAuthContactUsLinkText: 'support@spacent.com',
  emailAuthContactUsIfProblems:
    'jos sinulla on kysymyksiä tai haasteita kirjautumisessa!',

  emailAuthorizePaymentSubject: 'Spacent-jäsenyyden maksu vaatii vahvistusta',
  emailAuthorizePaymentTitle: 'Vahvista jäsenyyden maksu',
  emailAuthorizePaymentBody:
    'Spacent-jäsenyyden maksamiseen käytetty maksukortti vaatii vahvaa tunnistautumista. Jos olet kyseisen maksukortin haltija, voit siirtyä vahvistamaan jäsenyysmaksun painamalla alla olevaa nappia.',
  emailAuthorizePaymentButtonDoesntWork:
    'Mikäli nappi ei jostain syystä toimi, niin voit myös kopioida alla olevan linkin selaimesi osoitepalkkiin.',
  emailAuthorizePaymentButton: 'Siirry maksun vahvistamiseen',
  emailProjectSpaceSubject: 'Uusi työhuonevaraus',
  emailProjectSpace: 'Olet varannut seuraavan työhuoneen: ',
  emailProjectSpaceArrivalInfo: 'Ohjeet saapuessasi varattuun tilaan',

  // atom / molecule specific
  pickerComponentOk: 'OK',
  pickerComponentCancel: 'Peruuta',
  pickerComponentToday: 'Tänään',
  openingHoursClosed: 'Kiinni',
  openingHoursAlwaysOpen: 'Auki 24/7',
  openingHoursCustom: 'Vapaasti määriteltävä',
  localizableContentAdd: 'Lisää',

  // errors
  authError: 'Käyttö estetty. Ole hyvä ja kirjaudu uudelleen.',
  reservationsFetchError:
    'Varauksia haettaessa tapahtui virhe. Yritä myöhemmin uudelleen.',
  reservationCreationError:
    'Varausta luotaessa tapahtui virhe. Yritä myöhemmin uudelleen.',
  reservationCancelError: 'Peruutus epäonnistui. Yritä myöhemmin uudelleen.',
  reservationConflictError: 'Varaus epäonnistui: tila oli jo varattu.',
  reservationUpdateError:
    'Varauksen muutos epäonnistui. Yritä myöhemmin uudelleen.',
  reservationUpdateConflictError:
    'Varauksen muutos epäonnistui: tila oli jo varattu.',
  spacesFetchError:
    'Tiloja haettaessa tapahtui virhe. Yritä myöhemmin uudelleen.',
  addCardError: 'Kortin lisääminen ei onnistunut',
  createOrganizationError:
    'Ikävä kyllä tapahtui virhe ja liittyminen Spacent-palveluun keskeytyi. Ole hyvä tarkista syöttämäsi tiedot ja yritä uudelleen.',
  generalCreditCardError:
    'Ikävä kyllä emme onnistuneet saattamaan näitä kortin maksutietoja käyttöön.',
  retryCreditCardError:
    'Ikävä kyllä emme onnistuneet varmentamaan luottokorttiasi. Ole hyvä ja yritä uudelleen.',
  reservationTimeNotAllowed:
    'Varaaminen ei ole sallittu annettuna ajankohtana.',
  conflictingReservation:
    'Tila on jo varattuna kyseisenä ajankohtana. Ole hyvä ja valitse toinen ajankohta.',

  // Stripe error messages
  stripe_card_declined: 'Korttiasi ei hyväksytty.',
  stripe_card_declined_card_not_supported: 'Korttisi ei ole tuettu.',
  stripe_card_number_in_name_field: 'Älä kirjoita kortin numeroa nimikenttään',
  stripe_card_number_in_postal_code_field:
    'Älä kirjoita kortin numeroa postinumerokenttään',
  stripe_customer_canceled_authentication:
    'Sinun täytyy tunnistautua jatkaaksesi maksutapahtumaa.',
  stripe_email_invalid: 'Sähköpostiosoite ei ole kelvollinen.',
  stripe_expired_card: 'Korttisi ei ole enää voimassa.',
  stripe_incomplete_au_bank_account_bsb: 'BSB-koodia ei ole annettu kokonaan.',
  stripe_incomplete_au_bank_account_number:
    'Tilinumeroa ei ole annettu kokonaan.',
  stripe_incomplete_card: 'Ole hyvä ja täytä kaikki tiedot.',
  stripe_incomplete_cvc: 'Kortin turvanumeroa ei ole annettu kokonaan.',
  stripe_incomplete_expiry: 'Kortin voimassaoloaikaa ei ole annettu kokonaan.',
  stripe_incomplete_iban: 'IBAN-numeroa ei ole annettu kokonaan.',
  stripe_incomplete_number: 'Korttisi numeroa ei ole annettu kokonaan.',
  stripe_incomplete_payment_details: 'Ole hyvä ja täytä kaikki maksun tiedot.',
  stripe_incomplete_zip: 'Postinumeroa ei ole annettu kokonaan.',
  stripe_incorrect_cvc: 'Korttisi turvanumero ei täsmää.',
  stripe_incorrect_number: 'Korttisi numero on virheellinen.',
  stripe_incorrect_zip: 'Korttisi numero ja postinumerosi eivät täsmää.',
  stripe_invalid_au_bank_account_bsb: 'Annettu BSB-koodi on virheellinen',
  stripe_invalid_au_bank_account_number_testmode:
    'Annettua tilinumeroa ei voi käyttää testitilassa.',
  stripe_invalid_cvc: 'Korttisi turvanumero on virheelinen.',
  stripe_invalid_expiry_month: 'Korttisi voimassaoloaika on virheellinen',
  stripe_invalid_expiry_month_past:
    'Korttisi voimassaoloaika on jo umpeutunut.',
  stripe_invalid_expiry_year: 'Korttisi voimassaoloajan vuosi on virheellinen.',
  stripe_invalid_expiry_year_past:
    'Korttisi voimassaoloajan vuosi on menneisyydessä.',
  stripe_invalid_iban: 'Annettu IBAN-numero on virheellinen',
  stripe_invalid_iban_country_code:
    'Annettu IBAN-numero on virheellinen, sen alussa oleva maakoodi ei ole tuettu maakoodi.',
  stripe_invalid_iban_start:
    'IBAN-numeron tulee alkaa kahden kirjaimen maakoodilla.',
  stripe_invalid_number: 'Korttisi numero on virheellinen.',
  stripe_payment_intent_authentication_failure:
    'Emme onnistuneet maksutavan autentikoimisessa. Ole hyvä ja valitse toinen maksutapa ja yritä sen jälkeen uudelleen.',
  stripe_payment_intent_unexpected_state: 'Käsittelyssä tapahtui virhe.',
  stripe_process_error_intransient: 'Korttisi käsittelyssä tapahtui virhe.',
  stripe_processing_error:
    'Korttisi käsittelyssä tapahtui virhe. Yritä uudelleen hetken kuluttua.',
  stripe_setup_intent_authentication_failure:
    'Emme onnistuneet maksutavan autentikoimisessa. Ole hyvä ja valitse toinen maksutapa ja yritä sen jälkeen uudelleen.',
  stripe_setup_intent_unexpected_state: 'Käsittelyssä tapahtui virhe.',
  stripe_unexpected: 'Ennakoimaton virhe.',

  // Accessy integration
  accessyInvitation:
    'Hyväksymällä kutsun pystyt avaamaan ovia tiloihin jotka varaat Spacent-sovelluksella.',
  accessyAcceptInvitation:
    'Sinulle on lähetetty tekstiviestinä kutsu, jotta voit asentaa Accessy-sovelluksen jolla voit avata tilan oven. Ole hyvä ja seuraa tekstiviestin linkkiä asentaaksesi Accessy-sovelluksen.',

  // invite a guest
  verify: 'Vahvista',
  acceptInvite: 'Hyväksy kutsu',
  acceptInvitePageTitle: 'Kutsu Spacent-kohteeseen',
  youHaveBeenInvited: 'Sinut on kutsuttu',
  inviteIAgreeTo: 'Hyväksyn Spacentin',
  agreeToRest:
    ', sekä annan Spacentille luvan tallentaa henkilötietoni ja ottaa minuun yhteyttä varaukseeni liittyen.',
  userTerms: 'Käyttöehdot',
  inviteMoreInfoInEmail: `Lähetimme tarkemmat tiedot varauksesta sähköpostiisi`,
  verifyYourEmail: 'Vahvista sähköpostiosoitteesi jatkaaksesi',
  inviterNoInvitesLeft: 'Kutsujalla ei ole enään kutsuja jäljellä',
  inviteUnavailable: 'Kutsu ei ole enää saatavilla',
  inviterReservationUnavailable:
    'Valitettavasti varaus johon kutsu perustuu ei ole enää ajankohtainen.',
  reservationNotFound: 'Varausta ei löydy.',
  reservationCancelled: 'Varaus peruttu.',
  reservationCancelingDenied: 'Et voi perua tätä varausta.',
  reservationCancelingFailed: 'Varauksen peruminen epäonnistui',
  reservationFailed: 'Varauksen luonti epäonnistui',
  missingEmail: 'Sähköposti puuttuu.',
  invitationsAllSpent: 'Kaikki kutsut on käytetty.',
  duplicateInvitationAccept: 'Olet jo hyväksynyt tämän kutsun.',
  missingInvitation: 'Kutsu puuttuu.',
  roomIsFull: 'Tila on täynnä.',
  unknownReason: 'Tuntematon virhe.',
  tryAgainLater: 'Yritä myöhemmin uudelleen.',

  // statistcs
  statistics: 'Tilastot',
  hostStatistics: 'Operaattoritilastot',
  impactDashboard: 'Säästölaskuri',
  lastMonth: 'Viime kuukausi',
  lastWeek: 'Viime viikko',
  activeUsers: 'Aktiivisia käyttäjiä',
  noStatisticsData: 'Aikavälillä ei ole tehty varauksia.',
  totalReservations: 'Varauksia yhteensä',
  totalNetworkReservations: 'Varauksia verkostoon yhteensä',
  usedCapacity: 'Käytetty kapasiteetti',
  totalReservationsBySpace: 'Aktiiviset varaukset tiloittain',
  totalReservationsBySpaceExplanation:
    'Aktiivisten varausten määrä tilaa kohden annetulla aikavälillä.',
  totalReservationsByWeekday: 'Varaukset viikonpäivittäin',
  totalReservationsByWeekdayExplanation:
    'Aktiivisten varausten määrä viikonpäivittäin annetulla aikavälillä.',
  weekday: 'Viikonpäivä',
  activeReservationsByDuration: 'Varausten kesto',
  space: 'Tila',
  activeReservationDurationExplanation:
    'Aikavälillä aktiivisten varausten keston jakauma.',
  averageRating: 'Palautteen keskiarvo',
  createdReservations: 'Luotuja varauksia',
  cancelledReservations: 'Peruttuja varauksia',
  totalUsers: 'Käyttäjien kokonaismäärä:',
  ofWhich: 'joista',
  wereActive: 'oli aktiivisia',
  duration: 'Kesto',
  dayAbbreviation: 'p',
  hourAbbreviation: 't',
  print: 'Tulosta',
  statisticsLocationFilterDescription: 'Rajaa tilastot lokaation perusteella.',

  // Impact parameters
  numberOfEmployees: 'Työntekijöiden lukumäärä',
  mobileWorkers: 'Mobiilityöntekijät',
  mobileWorkersPercent: 'Mobiilityöntekijöiden %',
  daysDescription: 'päivää',
  daysInFlexLocations: 'Päiviä joustotiloissa',
  inFlexLocations: 'Joustotiloissa',
  daysWorkingFromHome: 'Päiviä etätyötä kotoa',
  workingFromHome: 'Etätyötä kotoa',
  daysAtFixedOffice: 'Päiviä kiinteässä toimistossa',
  atFixedOffice: 'Kiinteässä toimistossa',
  modeledFromNumberOfEmployees: 'Mallinnettu työntekijöiden lukumäärästä',
  exactFixedOfficeSize: 'Toimiston neliömäärä (m²)',
  areaPerEmployee: 'Neliöitä per työntekijä',
  useDetailedModel: 'Käytä yksityiskohtaista mallia',
  areaPerPerson: 'Neliömäärä per henkilö (m²)',
  fixedOfficeCostPerM2: 'Kiinteän toimiston kuukausikulu',
  impactParametersSaved: 'Vaikutusparametrit on tallennettu.',
  impactParametersSaveError:
    'Voi ei 😭. Vaikutusparametrien tallennus ei onnistunut.',

  // Impact dashboard
  impactDashboardTitle: 'Hybridityön vaikutus',
  impactIntroduction:
    'Mallinna toimiston ja etätyön vaikutusta kustannuksiin, CO₂-päästöihin ja työntekijöiden ajankäyttöön. Syötä oman organisaatiosi ja toimistosi tiedot ja löydä omalle yrityksellesi paras työnteon malli.',
  startImpactCalculation: 'Aloita',
  edit: 'Muokkaa',
  totalImpact: 'Kokonaisvaikutus',
  potentialForFixedLocationDownsizing: 'Oman tilan vähentämisen potentiaali',
  potentialForFixedLocationDownsizingTooltip:
    'Kuinka monta neliömetriä pienemmän oman toimiston tarvitsette nykyiseen verrattuna',
  totalCostSavings: 'Rahallinen säästö yhteensä',
  totalCostSavingsTooltip:
    'Paljonko rahaa yrityksenne voi säästää siirryttäessä monipaikkaiseen työympäristöön',
  occupancyCostSavings: 'Säästö per työntekijä',
  occupancyCostSavingsTooltip:
    'Kuinka paljon rahaa yrityksenne säästää per henkilöstötyövuosi',
  co2Saved: 'CO₂-säästöt',
  co2SavedTooltip:
    'Oman toimitilan pienentämisestä Spacentin palvelun tukemana syntyvät CO₂-säästöt',
  co2SavedDescription: 'kilogrammaa vuodessa',
  fixedOffice: 'Ainoastaan oma kiinteä toimisto',
  fixedOfficeSize: 'Toimiston koko',
  fixedOfficeSizeTooltip: 'Kiinteän toimitilan neliömäärä',
  squareMeters: 'm²',
  fixedOfficeCost: 'Toimiston kuukausikulu',
  fixedOfficeCostTooltip:
    'Toimiston kokonaiskulu kuukaudessa antamaasi neliökohtaiseen kokonaiskuluun perustuen',
  hybridWorkplace: 'Oma toimisto + etätyöt kotoa + etätyöt joustotiloista',
  reducedFixedOfficeSize: 'Pienempi oma toimisto',
  reducedFixedOfficeSizeTooltip: 'Oman toimiston neliömäärä',
  reducedFixedOfficeCost: 'Pienempi oman toimiston kuukausikulu',
  reducedFixedOfficeCostTooltip:
    'Oman toimiston kuukausikulu perustuen antamaasi kuluun per toimiston neliömetri',
  requiredFlexUse: 'Joustotilojen tarve',
  requiredFlexUseTooltip:
    'Kuinka monta tuntia töitä tehdään joustotiloista kuukaudessa',
  requiredFlexUseDescription: 'tuntia kuukaudessa',
  flexUseCost: 'Kustannus kolmansista tiloista',
  flexUseCostTooltip:
    'Joustavista työtiloista tehdyn työn kustannus kuukaudessa',
  totalHybridWorkplaceCosts: 'Monipaikkaisen työmallin kokonaiskustannus',
  totalHybridWorkplaceCostsTooltip:
    'Oman toimiston ja joustotilojen kokonaiskustannus kuukaudessa',

  // user statistics
  occupierStatistics: 'Käyttötilastot',
  occupierStatisticsTitle: 'Käyttötilastot',
  useAnotherTimezone: 'Valitse käytettävä aikavyöhyke',
  comparingToPrevious: 'Vertailukohtana edellinen',
  comparingToPreviousShort: 'Edellinen',
  compareYear: 'vuosi',
  compareYears: 'vuotta',
  compareMonth: 'kuukausi',
  compareMonths: 'kuukautta',
  compareWeek: 'viikko',
  compareWeeks: 'viikkoa',
  compareDay: 'päivä',
  compareDays: 'päivää',
  hoursUnit: 'h',
  starsUnit: '/5',
  userStatistics: 'Käyttäjätilastot',
  usersWhoMadeReservations: 'Varauksen tehneitä käyttäjiä',
  reserversFromOwnOffices: 'Oman toimiston käyttäjät',
  reserversFromSpacent: 'Spacentin verkoston käyttäjät',
  averageUserRating: 'Varausten arvioiden keskiarvo',
  useBySpaceType: 'Oman toimiston ja Spacentin verkoston käyttö',
  ownSpaces: 'Oma toimisto',
  spacentNetwork: 'Spacentin verkosto',
  networkUserBySpaceType: 'Spacentin verkoston käyttö tilatyypeittäin',
  hotDeskReservations: 'Työpiste',
  privateOfficeReservations: 'Työhuone',
  meetingRoomReservations: 'Neuvotteluhuone',
  usageStatistics: 'Käyttötilastot',
  networkUsageStatistics: 'Spacentin verkoston käytön tilastoja',
  networkStatisticsBySpaceType: 'Spacentin verkoston tilastoja tilatyypeittäin',
  networkHotDeskReservationsByDay:
    'Verkoston työpisteiden varaukset päivittäin',
  networkMeetingRoomHoursByDay:
    'Verkoston neuvotteluhuoneiden varaukset päivittäin',
  networkPrivateOfficeRoomHoursByDay:
    'Verkoston työhuoneiden varaukset päivittäin',
  ownSpacesStatisticsBySpaceType: 'Oman toimiston tilastoja tilatyypeittäin',
  ownSpacesHotDeskReservationsByDay: 'Omien työpisteiden varaukset päivittäin',
  ownSpacesMeetingRoomHoursByDay:
    'Omien neuvotteluhuoneiden varaukset päivittäin',
  ownSpacesPrivateOfficeRoomHoursByDay:
    'Omien työhuoneiden varaukset päivittäin',
  dailyPeakOfNetworkReservations: 'Yhden päivän varaushuippu',
  avgDurationOfNetworkReservations: 'Varauksen keskimääräinen pituus',
  avgDurationOfNetworkMeetingRoomReservations:
    'Neuvotteluhuoneen varauksen keskimääräinen pituus',
  avgDurationOfOwnMeetingRoomReservations:
    'Neuvotteluhuoneen varauksen keskimääräinen pituus',
  dailyPeakOfHotDeskReservations: 'Työpisteiden yhden päivän varaushuippu',
  dailyPeakOfMeetingRoomReservations:
    'Neuvotteluhuoneiden yhden päivän varaushuippu',
  avgDurationOfMeetingRoomReservations: 'Varauksen keskimääräinen pituus',
  totalDurationOfMeetingRoomReservations:
    'Neuvotteluhuoneiden varausten kesto yhteensä',
  date: 'Päivä',
  hoursLegend: 'Tuntia',
  hotDeskReservationsByDay: 'Työpisteiden varaukset päivittäin',
  meetingRoomUseByDay: 'Neuvotteluhuoneiden varaukset päivittäin',
  networkReservationsByDay: 'Varaukset Spacentin verkostosta päivittäin',
  topNetworkLocations: 'Eniten käytetyt Spacentin verkoston lokaatiot',
  topRatedNetworkLocations:
    'Parhaat arviot saaneet Spacentin varkoston lokaatiot',
  totalOwnSpaceReservationsBySpace:
    'Varauksia tehty kuhunkin omaan tilaan yhteensä',
  noData: 'Ei varauksia',
  uniqueVisitors: 'Yksittäisiä kävijöitä',
  showAll: 'Näytä kaikki',
  showLess: 'Näytä vähemmän',
  givenRatings: 'Annettujen arvioiden määrä',
  reservationsCount: 'Varauksia',
  selectedTimeFrame: 'Valittu aikajakso',
  nameless: 'Nimetön',

  // Push notifications
  pnReservationStartTitle: 'Varauksesi alkaa pian',
  pnReservationStartBody: 'Tarkista saapumisohjeet tai peru varaus.',

  // Payment method webview
  addCardHeader: 'Lisää uusi kortti',

  // My Location translations
  organizationLocations: 'Organisaation tilat',
  colleaguesReservations: 'Kollegoiden tilavaraukset',
  colleaguesReservationsPlaceholder:
    'Tähän tulevat näkyviin kollegoidesi jakamat varaukset',
  userLastVisitedLocations: 'Viimeksi käydyt',

  // usergroups -- app
  userGroupsOfMyOrganization: 'Oman organisaatiosi ryhmät',
  userGroupsOfMyOwn: 'Omat ryhmäsi',
  userGroupsOfOtherUsers: 'Muut ryhmät joihin kuulut',

  // BetaTag
  betaTagTooltip:
    'Tämä ominaisuus on vielä BETA-vaiheessa. Se on siis mielestämme hyödyllinen ja toimii tarkoitetulla tavalla, mutta haluaisimme saada siitä palautetta!',

  // App update descriptions for application (can be markdown)
  appRecommendUpdateDescriptions: '',
  appRequiredUpdateDescription: '',

  // Mui-Table
  muiTableRowsPerPage: 'Rivejä per sivu:',

  // HelpTooltip
  hideSpaceFromCalendarHelp:
    'Syy tilan aukiolon muokkaamiseen, jotta muistat sen myöhemmin ;)',

  webMembershipDefaultAllUsersUsergroupName: 'Vain kertavarauksia',
  webMembershipBasicMembershipUsergroupName: 'Basic',
  webBusinessMembershipUsergroupName: 'Business',
  webBusinessPlusMembershipUsergroupName: 'Business+',

  ...fiCountryCodeTranslations,

  // Country code overrides
  CA: 'Kanada',
  CN: 'Kiina',
  FI: 'Suomi',
  GR: 'Kreikka',
  SE: 'Ruotsi',
  US: 'USA',
  AT: 'Itävalta',
  EE: 'Viro',
  ES: 'Espanja',
  LT: 'Liettua',
  LV: 'Latvia',
  NO: 'Norja',
  DK: 'Tanska',
  RO: 'Romania',
  CH: 'Sveitsi',
  NL: 'Alankomaat',
  DE: 'Saksa',
  SG: 'Singapore',
  GB: 'Yhdistynyt kuningaskunta',
  BE: 'Belgia',
  PT: 'Portugali',
  PL: 'Puola',
  CZ: 'Tšekki',

  EUR: 'Euro (€)',
  SEK: 'Ruotsin kruunu (kr)',
  NOK: 'Norjan kruunu (kr)',
  DKK: 'Tanskan kruunu (kr)',
  RON: 'Romanian leu (lei)',
  CHF: 'Sveitsin frangi (fr)',
  PLN: 'Puolan zloty (zł)',
  GBP: 'Britannian punta (£)',
  CZK: 'Tšekin koruna (Kč)',
  USD: 'Yhdysvaltain dollari ($)',
  CAD: 'Kanadan dollari (CAN$)',
}

const validations = {
  ...en.validations,
  required: ({ field }: { field: string }) => `${field} on pakollinen tieto`,
  email: ({ field }: { field: string }) =>
    `Kentän ${field.toLowerCase()} on oltava kelvollinen sähköpostiosoite`,
  min: ({ field, min }: { field: string; min: string }) =>
    `Kentän ${field.toLowerCase()} lyhyin pituus on ${min} merkkiä`,
  max: ({ field, max }: { field: string; max: string }) =>
    `Kentän ${field.toLowerCase()} suurin pituus on ${max} merkkiä`,
  phoneFormat: ({ field }: { field: string }) =>
    `Kentän ${field.toLowerCase()} arvon tulee alkaa plus-merkillä ja sisältää ainoastaan numeroita, esimerkiksi: +123456789`,
  domainSyntax: ({ field }: { field: string }) =>
    `Kentän ${field} pitää koostua pisteillä erotetuista sanoista, kuten "example.com".`,
  domainAvailability: ({ field }: { field: string }) =>
    `Kentän ${field} arvo on muiden organisaatioiden käytössä tai sitä ei käytetä sähköpostiosoitteissa.`,
  domains: ({ field }: { field: string }) =>
    `Kentän ${field} arvon pitää muodostus pisteellä erotetuista sanoista, kuten "example.com".`,
  businessIdOnly: () => 'Vain y-tunnus (1234567-8, 1234567890)',
  minThreePhotos: () => 'Tarvitaan vähintään kolme kuvaa',
  mustBeUrl: ({ field }: { field: string }) =>
    `Kentän ${field} täytyy olla URL-muodossa`,
}

const formatters = {
  ...en.formatters,
  reservationNotification: (props: {
    roomName: string
    locationAddress: string
    startTime: string
    endTime: string
  }) =>
    `Tila ${props.roomName} varattiin osoitteessa ${props.locationAddress} alkaen ${props.startTime} ja päättyen ${props.endTime}.`,

  reservationConcernNotificationSubject: (props: {
    startTime: string
    locationAddress?: string
    roomName: string
  }) =>
    `Huomio koskien tilavaraustanne ${props.startTime} ${
      props.locationAddress
        ? `at ${props.locationAddress}`
        : `in ${props.roomName}`
    }`,

  cancellationNotification: (props: {
    roomName: string
    locationAddress: string
    startTime: string
    endTime: string
  }) =>
    `Tilan ${props.roomName} varaus osoitteessa ${props.locationAddress} alkaen ${props.startTime} ja päättyen ${props.endTime} on peruttu.`,

  colleaguesReservationsDescription: (props: {
    colleagues: number
    locations: number
    spaces: number
    when: string
  }) => {
    const plural = (n: number, unit: string, multiple: string) =>
      `${n} ${n === 1 ? unit : multiple}`

    return props.colleagues > 0
      ? [
          plural(props.colleagues, 'kollegasi', 'kollegaasi'),
          ` työskentelee ${props.when}\n`,
          plural(props.locations, 'rakennuksessa,', 'eri rakennuksessa,'),
          ' ',
          plural(props.spaces, 'tilassa', 'eri tilassa'),
          `.`,
        ].join('')
      : 'Kollegasi eivät ole tehneet tilavarauksia. Aloita sinä!'
  },

  corporateSubscriptionMembershipFor: (props: { users: number }) =>
    `Jaettu coworking-passi korkeintaan ${props.users} hengelle`,

  corporateMembershipsUtilizationExplained: (props: {
    reservationsUsed: any
    reservationsProvided: any
    memberships: any
  }) => [
    props.reservationsUsed,
    'käyntiä varattuna',
    props.memberships,
    'jäsenyydelle tilatuista',
    props.reservationsProvided,
    'käynnistä.',
  ],

  membershipUnitPriceTotalMonthly: (props: {
    unit: string
    count: any
    price: any
    discount: any
    total: any
  }) => [
    props.count,
    props.unit,
    ' yksikköhintaan',
    props.price,
    ';',
    props.discount,
    '- yhteensä',
    props.total,
    'kuussa.',
  ],

  dedicatedMembershipUtilizationExplained: (props: {
    reservationCount: any
    memberCount: any
  }) => [
    props.reservationCount,
    'käyntiä varattuna',
    props.memberCount,
    'kävijän toimesta tässä kuussa.',
  ],

  maximumReservationLengthInHours: (props: { hours: number }) =>
    `Pisin sallittu varausaika on ${props.hours} tuntia`,

  smsVerificationFormat: (props: { code: string }) =>
    `Ole hyvä, koodilla ${props.code} voit vahvistaa puhelinnumerosi Spacentissa.`,

  smsCancelledFormat: (props: { dateTime: string }) =>
    `Varauksesi peruttiin. \nIkävä kyllä Spacent-varauksesi ajalle ${props.dateTime} peruttiin.`,

  pnReservationCancelledTitle: 'Varauksesi on peruttu',
  pnReservationCancelledBody: (props: { dateTime: string }) =>
    `Varauksesi ${props.dateTime} on jouduttu perumaan tilan puolesta. Avaa sovellus ja tee uusi varaus.`,

  pnTeamRoomReservationTitle: 'Uusi tiimitilavaraus',
  pnTeamRoomReservationBody: (props: {
    locationName: string
    cityName: string
    dateTime: string
  }) =>
    `Kollegasi lisäsi sinut tiimitilavaraukseensa: ${props.dateTime}, ${props.locationName}, ${props.cityName}. Avaa sovellus nähdäksesi varauksen tiedot.`,

  reservationIsOpenOn: (props: AllowedReservationTimes) =>
    ['Varaaminen on avoinna:', ...openTimesForDays(props, timeFormats)].join(
      '\n  ',
    ),

  shorthandPricingFree: () => 'Ilmainen',

  shorthandPricingHourly: (props: {
    pricePerHour: number
    currency: CurrencyCode
  }) => `${props.pricePerHour} ${constantStrings[props.currency]} / tunti`,

  shorthandPricingTieredByUnit: (props: {
    lowestPrice: number
    highestPrice: number
    unit: TieredUnit
    currency: CurrencyCode
  }) =>
    `${props.lowestPrice} ${constantStrings[props.currency]} - ${
      props.highestPrice
    } ${constantStrings[props.currency]} / ${formatters.tieredUnitToShorthand({
      unit: props.unit,
    })}`,

  shorthandPricingTieredByLength: (props: {
    lowestPrice: number
    highestPrice: number
    currency: CurrencyCode
  }) =>
    `${props.lowestPrice} ${constantStrings[props.currency]} - ${
      props.highestPrice
    } ${constantStrings[props.currency]}`,

  shorthandPricingByVolume: (props: {
    lowestVolumePrice: [number, TieredUnit]
    highestVolumePrice?: [number, TieredUnit]
    currency: CurrencyCode
  }) => {
    return `${props.lowestVolumePrice[0]} ${
      constantStrings[props.currency]
    } / ${formatters.tieredUnitToShorthand({
      unit: props.lowestVolumePrice[1],
    })} ${
      props.highestVolumePrice
        ? ` - ${props.highestVolumePrice[0]} ${
            constantStrings[props.currency]
          } / ${formatters.tieredUnitToShorthand({
            unit: props.highestVolumePrice[1],
          })}`
        : ''
    }`
  },

  effectivePublishingStatusExplanation: (
    actualPublishingStatus: PublishingStatus,
    effectivePublishingStatus: PublishingStatus,
  ) =>
    `Tilan efektiivinen näkyvyys on ${constantStrings[effectivePublishingStatus]} valituista "${constantStrings.offerPricingAndAvailability}" -asetuksista johtuen.`,

  tieredUnitToShorthand: (props: { unit: TieredUnit }) => {
    const shorthand: Record<TieredUnit, string> = {
      hours: 'tunti',
      months: 'kuukausi',
      days: 'päivä',
      weeks: 'viikko',
    }

    return shorthand[props.unit]
  },

  emailReservedProjectSpace: (props: { spaceName: string }) =>
    `Olet varannut ${props.spaceName} työhuoneen!`,

  projectSpaceNotification: (props: {
    roomName: string
    startTime: string
    endTime: string
  }) =>
    `Varauksesi tilalle ${props.roomName} on voimassa alkaen ${props.startTime} ja päättyen ${props.endTime}.`,

  capacityFullMessage: (props: { overlappingReservations: ReservedSlot[] }) =>
    [
      'Tila on jo varattu seuraavina ajankohtina:',
      ...props.overlappingReservations.map(
        (r) =>
          `${moment(r.start).format(
            timeFormats.momentDateTimeFormat,
          )} - ${moment(r.end).format(timeFormats.momentTimeFullFormat)}`,
      ),
    ].join('\n'),

  tieredPricingLimitsReservationLength: (props: { hours: number }) =>
    `Tämä rajoittaa pisimmän varausajan ${props.hours} tuntiin.`,

  usergroupMembersCount: (props: { count: number | undefined }) =>
    props.count ? `${props.count} jäsentä` : 'Ryhmässä ei ole yhtään jäsentä',

  confirmDetachLobbyUsergroup: (props: { name: string }) =>
    `Lopetetaanko ryhmän "${props.name}" oikeus hallita varauksia täällä?`,

  lobbyUsergroupNameDefault: (props: { name: string | undefined }) =>
    `Aulapalvelu ${props.name ?? ''}`,

  longhandSpaceType: (spaceType: SpaceType) =>
    ({
      meeting: 'Neuvottelutila',
      desks: 'Deski',
      project: 'Työtila',
      other: 'Muu',
      team: 'Tiimihuone',
    }[spaceType] ?? ''),

  shorthandSpaceType: (props: { type: SpaceType }) =>
    ({
      meeting: 'Neukkari',
      desks: 'Deski',
      project: 'Työtila',
      other: 'Muu',
      team: 'Tiimitila',
    }[props.type] ?? ''),

  someReservations: (props: { count: number }) =>
    props.count === 1 ? `1 varaus` : `${props.count} varausta`,

  spaceToHideContainsSomeReservations: (props: {
    reservations: number
    spaces: number
  }) => {
    const tiloihin =
      props.spaces > 1 ? `${props.spaces} tilaan` : `Yhteen tilaan`
    const varauksia =
      props.reservations > 1 ? `${props.reservations} varausta` : 'varaus'

    return `${tiloihin} on jo olemassa ${varauksia} tuona aikana. Nämä varaukset tulee perua ennen kuin voit estää varaukset.`
  },

  viewSomeReservations: (props: { count: number }) =>
    props.count === 1 ? 'Näytä varaus' : `Näytä varaukset`,

  cancelSomeReservations: (props: { count: number }) =>
    props.count === 1 ? `Peruuta varaus` : `Peruuta ${props.count} varausta.`,

  cancelledSomeReservations: (props: { count: number }) =>
    props.count === 1 ? `Varaus peruttu.` : `${props.count} varausta peruttu.`,

  failedToCancelSomeReservations: (props: { count: number }) =>
    props.count === 1
      ? `Varauksen peruutus epäonnistui.`
      : `${props.count} varauksen peruutus epäonnistui.`,

  discountLimited: (props: { months: number }) =>
    props.months > 1
      ? `ensimmäisen ${props.months} kuukauden ajan`
      : `ensimmäisen kuukauden ajan`,

  afterDiscountLimited: (props: { months: number }) =>
    props.months > 1
      ? `ensimmäisen ${props.months} kuukauden jälkeen`
      : `ensimmäisen kuukauden jälkeen`,

  inviteAcceptFailedForInviterNoInvitesLeft: (props: { invitee: string }) =>
    `${props.invitee} ei voinut hyväksyä Spacent-kutsuasi, koska sinulla ei ole kutsuja jäljellä.`,

  inviteAcceptedForInviter: (props: {
    invitee: string
    where: string
    when: string
  }) =>
    `${props.invitee} hyväksyi Spacent-kutsusi kohteeseen ${props.where} ajankohtana ${props.when}.`,

  inviteCancelledForInviter: (props: {
    invitee?: string
    where: string
    when: string
  }) =>
    `${props.invitee} perui osallistumisensa kohteessa ${props.where} ajankohtana ${props.when}.`,

  totalCostSavingsDescription: (props: {
    currencySymbol: string
    percent: number
  }) => `${props.currencySymbol} / kk (${props.percent} %)`,

  occupancyCostSavingsDescription: (props: { currencySymbol: string }) =>
    `${props.currencySymbol} / kk / FTE`,

  currencyPerMonth: (props: { currencySymbol: string }) =>
    `${props.currencySymbol} / kk`,

  fixedOfficeCostPerM2Description: (props: { currencySymbol: string }) =>
    `${props.currencySymbol} / m² / kk`,

  flexCostPerHour: (props: { currencySymbol: string }) =>
    `Joustotilojen käyttökustannukset (${props.currencySymbol} / h)`,

  registeredUsersOn: (props: { date: string }) =>
    `Rekisteröityneet käyttäjät ${props.date.replace(/ /g, '\xa0')}`,

  // push notifications
  pushNotificationBody: (props: { cancellable: boolean }): string =>
    props.cancellable
      ? 'Lue saapumisohjeet ennen varauksesi alkua tai peru varaus.'
      : 'Lue saapumisohjeet ennen varauksesi alkua.',

  pnBusinessPassTitle: 'Kokeile kaikkia tiloja!',
  pnBusinessPassBody:
    'Klikkaa tästä kokeillaksesi Business coworking-passia, jolla saat pääsyn laajempaan valikoimaan työtiloja.',
  pnBusinessPassActionPrefilledText: (props: { email: string }) =>
    `Olen kiinnostunut kokeilemaan kaikkia työtiloja Business coworking-passilla. Olkaa minuun yhteydessä (${props.email}) pian.`,

  customNetworkCount: (props: { count: number }): string =>
    `Mukautettu verkostosi koostuu tällä hetkellä ${props.count} lokaatiosta.`,

  heardFromSource: (heardFromSource: HeardFromSource): string => {
    const sources: Record<HeardFromSource, string> = {
      colleagues: 'Kollegoilta',
      other: 'Muualta',
      company_communication_channel: 'Yrityksen viestintäkanavasta',
      company_event: 'Yrityksen tapahtumasta',
      email_messaging: 'Sähköpostista',
      other_social_media: 'Sosiaalisesta mediasta (esim. LinkedIn, Instagram)',
      prefer_not_to_say: 'En halua kertoa',
      search_engine: 'Googlesta (tai muusta hakukoneesta)',
      social_media: 'Sosiaalisesta mediasta',
      word_of_mouth: 'Ystävältä/kollegalta',
    }

    return sources[heardFromSource] || heardFromSource
  },

  affectsXOtherSpaces(otherSpaceIdCount: number): string {
    return `Oletko varma, että haluat muuttaa hinnoittelua joka vaikuttaa ⚠️${otherSpaceIdCount} muuhun tilaan?`
  },

  cityInCountry(country: string): string {
    return `Kaupunki maassa ${country}`
  },
}

const sharedMembershipPricePointTitles: Record<
  number,
  { [legislationRegionId: string]: string }
> = {
  1: {
    minUpTo5memberships: '1-5 käyttäjää',
    maxUpTo10memberships: '6-10 käyttäjää',
  },
  6: {
    minUpTo5memberships: '1-5 käyttäjää',
    maxUpTo10memberships: '6-10 käyttäjää',
  },
  21: {
    twoToFiveMemberships: '2-5 käyttäjää',
    maxUpTo10memberships: '6-10 users',
  },
}

// full translation
export const fi: Translations = {
  ...formatters,
  ...constantStrings,
  validations,
  timeFormats,
  timeFormatters: generateTimeFormatters(timeFormats),
  formatters,
  constantStrings,
  sharedMembershipPricePointTitles,
}
